import React from "react";

import NumberFormat from 'react-number-format';

import { Button, TextField } from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import DoneIcon from "@mui/icons-material/Done";

import { formatNumberRu } from '@/core/helpers/PageHelpers';

import styles from "./ModalGoldAction.module.css";
import { ModalWindow } from '@/components';
import { useModalGoldActions } from './useModalGoldAction';
import { MigRate, RateModel } from '@/interface';

import { CurrencyModel } from '@/interface';
import { OpenWindowType } from '@/pages/Treasury/pages/TreasurePage/useTreasurePage';

export interface ModalGoldActionProps {
	openWindow: OpenWindowType;
	handleCloseWindow: () => void;
	goldCurrencies?: CurrencyModel[];
	handleGetDepartmentList: () => void;
	addEditCurrencies: (currency: CurrencyModel) => void;
	migRate?: MigRate;
}

export const ModalGoldAction = ({
	openWindow,
	handleCloseWindow,
	goldCurrencies,
	handleGetDepartmentList,
	addEditCurrencies,
	migRate,
}: ModalGoldActionProps): JSX.Element => {
	const {
		gold1,
		gold5,
		gold10,
		gold20,
		gold50,
		gold100,
		changeGolds,
		validateAddCurrency,
		handleAddCurrency,
		activeDepartment,
	} = useModalGoldActions(openWindow === "editCurrency", handleGetDepartmentList, handleCloseWindow, migRate, goldCurrencies);

	return (
		<ModalWindow
			open={openWindow === "addCurrency" || openWindow === "editCurrency"}
			title={`г.${activeDepartment?.code}, ${activeDepartment?.address}`}
			onClose={handleCloseWindow}
			maxWidth="md"
			fullWidth
			scroll="body"
			sx={{
				zIndex: (theme) => theme.zIndex.drawer + 1,
				"& .MuiPaper-root": {
					padding: '20px',
				},
				'& h3': {
					textAlign: 'center',
					marginBottom: '10px'
				}
			}}
		>
			<div className={styles.container}>
				<div className={styles.currency_title}>
					<p>{openWindow === "editCurrency" ? "Изменение валюты:" : "Добавление золота:"}</p>
					<h2>GOLD</h2>
					<p style={{ textAlign: "center" }}>Золотые слитки</p>
				</div>

				<div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
					<div className={styles.competitor_block}>
						<h2>МИГ</h2>
						{migRate ? (
							<>
								<div className={styles.rate_block}>
									<div className={styles.rate_text}>
										<p>Покупка</p>
										<div>{formatNumberRu(migRate?.buy)}</div>
									</div>
									<SyncAltIcon />
									<div className={styles.rate_text}>
										<p>Продажа</p>
										<div>{formatNumberRu(migRate?.sell)}</div>
									</div>
								</div>
							</>
						) : (
							<div className={styles.rate_block}>
								<p>Курс не найден</p>
							</div>
						)}
					</div>
				</div>

				<div className={styles.currency_calc}>
					<h3></h3>
					<h3>Курс покупки</h3>
					<h3>Курс продажи</h3>


					{[1, 5, 10, 20, 50, 100].map(item => (
						<React.Fragment key={item}>
							<div className={styles.rate_column}>
								<p>
									{item === 1 && gold1.code}
									{item === 5 && gold5.code}
									{item === 10 && gold10.code}
									{item === 20 && gold20.code}
									{item === 50 && gold50.code}
									{item === 100 && gold100.code}
								</p>
							</div>

							<div className={styles.rate_column}>
								<NumberFormat
									customInput={TextField}
									//Свойства TextField Mui5`
									autoComplete={"off"}
									disabled={item !== 1}
									fullWidth
									value={gold1.rates.buy ? gold1.rates.buy * item : ""}
									variant="outlined"
									sx={{
										borderRadius: "5px",
										position: "relative",
										"& input": {
											background: "white",
											textAlign: "center",
											fontSize: "20px",
											// fontWeight: "bold",
											padding: "4px 0",
											borderRadius: "5px",
										},
										"& fieldset": {
											textAlign: "center",
										},
										"& label": {
											width: "100%",
											textAlign: "center",
											transformOrigin: "top center",
											left: "-13px",
										},
										"& .Mui-disabled": {
											color: "black !important",
										},
										"&::before": {
											content: `${gold1.rates?.buy >= gold1.rates?.sale
												? '"Больше чем курс продажи"'
												: '""'}`,
											color: "#fd8b8b",
											position: "absolute",
											width: "106%",
											bottom: "-18px",
											fontSize: "15px",
											textAlign: "center",
											fontWeight: "bold",
										},
									}}
									onChange={(e) => changeGolds(+(+e.target.value.replace(/\s/g, "")).toFixed(2), gold1.rates.sale)}
									//Свойства NumberFormat
									thousandSeparator={" "}
									allowNegative={false}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>

							<div className={styles.rate_column}>
								<NumberFormat
									customInput={TextField}
									//Свойства TextField Mui5
									autoComplete={"off"}
									disabled={item !== 1}
									fullWidth
									value={gold1.rates.sale ? gold1.rates.sale * item : ""}
									variant="outlined"
									sx={{
										borderRadius: "5px",
										position: "relative",
										"& input": {
											background: "white",
											textAlign: "center",
											fontSize: "20px",
											// fontWeight: "bold",
											padding: "4px 0",
											borderRadius: "5px",
										},
										"& fieldset": {
											textAlign: "center",
										},
										"& label": {
											width: "100%",
											textAlign: "center",
											transformOrigin: "top center",
											left: "-13px",
										},
										"& .Mui-disabled": {
											color: "black !important",
										},
										"&::before": {
											content: `${gold1.rates?.buy >= gold1.rates?.sale
												? '"Меньше чем курс покупки"'
												: '""'}`,
											color: "#fd8b8b",
											position: "absolute",
											width: "106%",
											bottom: "-18px",
											fontSize: "15px",
											textAlign: "center",
											fontWeight: "bold",
										},
									}}
									onChange={(e) => changeGolds(gold1.rates.buy, +(+e.target.value.replace(/\s/g, "")).toFixed(2))}
									//Свойства NumberFormat
									thousandSeparator={" "}
									allowNegative={false}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
						</React.Fragment>
					))}
				</div>

				<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", }}>
					<Button
						variant="contained"
						size="large"
						startIcon={<ClearIcon />}
						sx={{
							background: "#b70000",
							"&:hover": {
								background: "#7f0101",
							},
						}}
						onClick={handleCloseWindow}
					>
						Отменить
					</Button>

					{openWindow === "addCurrency" && (
						<Button
							variant="contained"
							size="large"
							startIcon={<DoneIcon />}
							sx={{
								background: "#037703",
								"&:hover": {
									background: "#005f00",
								},
							}}
							disabled={validateAddCurrency(gold1)}
							onClick={handleAddCurrency}
						>
							Добавить валюту
						</Button>
					)}
					{openWindow === "editCurrency" && (
						<Button
							variant="contained"
							size="large"
							startIcon={<DoneIcon />}
							sx={{
								background: "#037703",
								"&:hover": {
									background: "#005f00",
								},
							}}
							disabled={validateAddCurrency(gold1)}
							onClick={async () => {
								await addEditCurrencies({ ...gold1, rates: [{ ...gold1.rates }] });
								await addEditCurrencies({ ...gold5, rates: [{ ...gold5.rates }] });
								await addEditCurrencies({ ...gold10, rates: [{ ...gold10.rates }] });
								await addEditCurrencies({ ...gold20, rates: [{ ...gold20.rates }] });
								await addEditCurrencies({ ...gold50, rates: [{ ...gold50.rates }] });
								await addEditCurrencies({ ...gold100, rates: [{ ...gold100.rates }] });
							}}
						>
							Добавить изменение
						</Button>
					)}
				</div>
			</div>
		</ModalWindow>
	);
};