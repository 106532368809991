import React from "react";

import { DepartmentTreasureProps } from "./DepartmentTreasure.props";

import { formatNumberRu } from '@/core/helpers/PageHelpers';

import { Button, ButtonGroup, IconButton, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import ModeIcon from '@mui/icons-material/Mode';
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

import styles from "./DepartmentTreasure.module.css";

const basePath = process.env.PUBLIC_URL;

export const DepartmentTreasure = ({
	department,
	className,
	viewOptomRate,
	setViewOptomRate,
	handleOpenSelectNewCurrencyWindow,
	handleOpenDeleteCurrencyWindow,
	clearEditCurrencies,
	editCurrencies,
	updateCurrencies,
	handleOpenEditCurrencyWindow,
	removeEditCurrency,
	migRatesList,
	bccRatesList,
	isAdmin,
	...props
}: DepartmentTreasureProps): JSX.Element => {
	const kztCurrency = department?.currencies?.find(c => c.code === "KZT");

	return (
		<div
			className={className + " " + styles.container}
			{...props}
		>
			{editCurrencies.length > 0 && (
				<>
					<h2 style={{ textAlign: "center", textDecoration: "underline", marginBottom: "20px", fontSize: "30px", color: "#e25500" }}>Изменение валют</h2>
					<h3 style={{ marginBottom: '10px' }}>
						{department.code}, {department.address}
					</h3>
				</>
			)}
			{
				editCurrencies.length === 0 && isAdmin &&
				<>
					<div style={{ display: "grid", justifyContent: "center", gridTemplateColumns: "46px", gap: "10px" }}>
						<Tooltip title="Добавить валюту" placement="top">
							<IconButton
								className={styles.addBtn + " " + styles.iconBtn}
								onClick={() => handleOpenSelectNewCurrencyWindow(department.id)}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						</Tooltip>
					</div>

					<div style={{ display: "flex", marginBottom: "10px" }}>
						<ButtonGroup style={{ margin: "auto" }}>
							<Button
								variant={!viewOptomRate ? "outlined" : "contained"}
								disabled={!viewOptomRate}
								sx={{
									fontWeight: "bold",
									background: `${!viewOptomRate ? "#da793f" : "white"}`,
									color: `${!viewOptomRate ? "white !important" : "black !important"}`,
									"&:hover": {
										backgroundColor: "#ecf7ff",
									},
								}}
								onClick={() => {
									setViewOptomRate(false);
								}}
							>
								Розница
							</Button>
							<Button
								variant={viewOptomRate ? "outlined" : "contained"}
								disabled={viewOptomRate}
								sx={{
									fontWeight: "bold",
									background: `${viewOptomRate ? "#da793f" : "white"}`,
									color: `${viewOptomRate ? "white !important" : "black !important"}`,
									"&:hover": {
										backgroundColor: "#ecf7ff",
									},
								}}
								onClick={() => {
									setViewOptomRate(true);
								}}
							>
								Оптом
							</Button>
						</ButtonGroup>
					</div>
				</>
			}

			<div className={styles.kzt_currency}>
				<p>
					<img src={`${basePath}/assets/images/Flags/KZT.png`} />
					KZT
				</p>
				<p>
					{kztCurrency?.cash?.balance ? formatNumberRu(kztCurrency?.cash?.balance) : formatNumberRu(0)} тг
				</p>
			</div>

			<div className={styles.currency_list}>
				<div className={styles.list_header}>
					<p>Валюта</p>
					<p style={{ textAlign: "center" }}>Покупка</p>
					<p style={{ textAlign: "center" }}>Продажа</p>
					<p style={{ textAlign: "center", position: "relative", right: "-35px" }}>Min/Max сумма</p>
					<p style={{ textAlign: "center" }}>Баланс</p>
					<p style={{ textAlign: "center" }}>МИГ</p>
					<p style={{ textAlign: "center" }}>BCC</p>
				</div>
				{department?.currencies?.filter(currency => currency.code !== "KZT" && !currency.code.match(/GOLD/gi))?.map((currency) => {
					const editCurrency = editCurrencies?.find(c => c?.code === currency?.code);
					editCurrency && editCurrency?.rates.sort(a => a?.limitFrom === 0 ? -1 : 1);
					return (
						<div
							key={currency.currencyId}
							className={styles.currency_row + " " + (editCurrency && (styles.currency_row_edit_mode + " " + styles.currency_row_edit_mode_active))}
							onClick={() => editCurrency && handleOpenEditCurrencyWindow(editCurrency ? editCurrency : currency)}
						>
							<div className={styles.btnRowActions} style={{ right: editCurrency ? "-30px" : "-80px" }}>
								{editCurrency ? (
									<Tooltip title="Отменить изменение" placement="top">
										<IconButton
											className={editCurrency ? styles.cancelEditBtn + " " + styles.iconBtn : styles.displayNone}
											onClick={(e) => {
												e.stopPropagation();
												editCurrency && removeEditCurrency(editCurrency);
											}}
										>
											<CancelIcon />
										</IconButton>
									</Tooltip>
								) : (
									<>
										<Tooltip title="Изменить курсы" placement="top">
											<IconButton
												className={styles.addBtn}
												onClick={() => handleOpenEditCurrencyWindow(editCurrency ? editCurrency : currency)}
											>
												<ModeIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title="Удалить валюту" placement="top">
											<IconButton
												className={styles.deleteBtn + " " + styles.iconBtn + " " + ((currency?.cash?.balance || 0) > 0 && styles.disabledButton)}
												disabled={(currency?.cash?.balance || 0) > 0}
												onClick={() => {
													handleOpenDeleteCurrencyWindow(currency?.currencyId || "", department.id);
												}}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									</>
								)}
							</div>
							<p>
								<img
									src={`${basePath}/assets/images/Flags/${currency.code}.png`}
								/>
								<div>
									<p>{currency.code}</p>
									<p style={{ fontSize: "12px", fontWeight: 400 }}>{currency.description}</p>
								</div>
							</p>
							{
								editCurrency ? (
									<>
										<p style={{ justifySelf: "center", display: "flex", flexDirection: "column" }}>
											<p>
												{currency?.rates[0]?.limitFrom === 0
													? currency?.rates[0]?.buy.toFixed(2) + (editCurrency && currency?.rates[0]?.buy !== editCurrency?.rates[0]?.buy ? " ➞ " + editCurrency?.rates[0]?.buy.toFixed(2) : "")
													: currency?.rates[1]?.buy.toFixed(2) + (editCurrency && currency?.rates[1]?.buy !== editCurrency?.rates[1]?.buy ? " ➞ " + editCurrency?.rates[1]?.buy.toFixed(2) : "")}
											</p>
											<p>
												{currency?.rates[0]?.limitFrom !== 0
													? currency?.rates[0]?.buy.toFixed(2) + (editCurrency && currency?.rates[0]?.buy !== editCurrency?.rates[0]?.buy ? " ➞ " + editCurrency?.rates[0]?.buy.toFixed(2) : "")
													: currency?.rates[1]?.buy.toFixed(2) + (editCurrency && currency?.rates[1]?.buy !== editCurrency?.rates[1]?.buy ? " ➞ " + editCurrency?.rates[1]?.buy.toFixed(2) : "")}
											</p>
										</p>
										<p style={{ justifySelf: "center", display: "flex", flexDirection: "column" }}>
											<p>
												{currency?.rates[0]?.limitFrom === 0
													? currency?.rates[0]?.sale.toFixed(2) + (editCurrency && currency?.rates[0]?.sale !== editCurrency?.rates[0]?.sale ? " ➞ " + editCurrency?.rates[0]?.sale.toFixed(2) : "")
													: currency?.rates[1]?.sale.toFixed(2) + (editCurrency && currency?.rates[1]?.sale !== editCurrency?.rates[1]?.sale ? " ➞ " + editCurrency?.rates[1]?.sale.toFixed(2) : "")}
											</p>
											<p>
												{currency?.rates[0]?.limitFrom !== 0
													? currency?.rates[0]?.sale.toFixed(2) + (editCurrency && currency?.rates[0]?.sale !== editCurrency?.rates[0]?.sale ? " ➞ " + editCurrency?.rates[0]?.sale.toFixed(2) : "")
													: currency?.rates[1]?.sale.toFixed(2) + (editCurrency && currency?.rates[1]?.sale !== editCurrency?.rates[1]?.sale ? " ➞ " + editCurrency?.rates[1]?.sale.toFixed(2) : "")}
											</p>
										</p>
										<p style={{ justifySelf: "center", alignItems: "center", display: "flex", flexDirection: "column", position: "relative", right: "-35px" }}>
											<p>
												{(currency?.rates[0]?.limitFrom === 0 ? formatNumberRu(currency?.rates[0]?.limitTo) : formatNumberRu(currency?.rates[1]?.limitTo))}
											</p>
										</p>
									</>
								) : (
									<>
										<p style={{ justifySelf: "center" }}>
											{!viewOptomRate &&
												(currency?.rates[0]?.limitFrom === 0
													? currency?.rates[0]?.buy.toFixed(2)
													: currency?.rates[1]?.buy.toFixed(2))}
											{viewOptomRate &&
												(currency?.rates[0]?.limitFrom !== 0
													? currency?.rates[0]?.buy.toFixed(2)
													: currency?.rates[1]?.buy.toFixed(2))}
										</p>
										<p style={{ justifySelf: "center" }}>
											{!viewOptomRate &&
												(currency?.rates[0]?.limitFrom === 0
													? currency?.rates[0]?.sale.toFixed(2)
													: currency?.rates[1]?.sale.toFixed(2))}
											{viewOptomRate &&
												(currency?.rates[0]?.limitFrom !== 0
													? currency?.rates[0]?.sale.toFixed(2)
													: currency?.rates[1]?.sale.toFixed(2))}
										</p>
										<p style={{ justifySelf: "center", position: "relative", right: "-35px" }}>
											{!viewOptomRate && (currency?.rates[0]?.limitFrom === 0 ? formatNumberRu(currency?.rates[0]?.limitTo) : formatNumberRu(currency?.rates[1]?.limitTo))}
											{viewOptomRate && (currency?.rates[0]?.limitFrom !== 0 ? formatNumberRu(currency?.rates[0]?.limitFrom) : formatNumberRu(currency?.rates[1]?.limitFrom))}
										</p>
									</>
								)
							}
							<p style={{ justifySelf: "center" }}>{formatNumberRu(currency?.cash?.balance || 0)}</p>
							<p style={{ justifySelf: "center" }}>
								{migRatesList?.find(migRate => migRate?.code === currency.code) && `${migRatesList?.find(migRate => migRate?.code === currency?.code)?.buy} - ${migRatesList?.find(migRate => migRate?.code === currency?.code)?.sell}`}
							</p>
							<p style={{ justifySelf: "center" }}>
								{typeof bccRatesList !== "string" && bccRatesList?.find(bccRate => bccRate?.currencyCode === currency?.code) && `${bccRatesList?.find(bccRate => bccRate?.currencyCode === currency?.code)?.sellRate} - ${bccRatesList?.find(bccRate => bccRate?.currencyCode === currency?.code)?.buyRate}`}
							</p>
						</div>
					);
				})}
			</div>

			{department.currencies.find(currency => currency.code.match(/GOLD/gi)) &&
				<div className={styles.currency_list} style={{ marginTop: "10px" }}>
					<div className={styles.list_header} style={{ gridTemplateColumns: "210px 230px 230px 1fr 1fr" }}>
						<p></p>
						<p style={{ textAlign: "center" }}>Покупка</p>
						<p style={{ textAlign: "center" }}>Продажа</p>
						<p style={{ textAlign: "center" }}>Баланс (шт.)</p>
						<p style={{ textAlign: "center" }}>МИГ</p>
					</div>
					{department?.currencies?.filter(currency => currency.code !== "KZT" && currency.code.match(/GOLD/gi))?.map((currency, index) => {
						const editCurrency = editCurrencies?.find(c => c?.code === currency?.code);
						editCurrency && editCurrency?.rates.sort(a => a?.limitFrom === 0 ? -1 : 1);
						return (
							<div
								key={currency.currencyId}
								className={styles.currency_row + " " + (editCurrency && index === 0 && (styles.currency_row_edit_mode + " " + styles.currency_row_edit_mode_active))}
								onClick={() => editCurrency && index === 0 && handleOpenEditCurrencyWindow(editCurrency ? editCurrency : currency)}
								style={{ gridTemplateColumns: "210px 230px 230px 1fr 1fr", cursor: index === 0 && editCurrency ? "pointer" : "unset" }}
							>
								{index === 0 && (
									<div className={styles.btnRowActions} style={{ right: editCurrency ? "-30px" : "-80px" }}>
										{editCurrency && index === 0 ? (
											<Tooltip title="Отменить изменение" placement="top">
												<IconButton
													className={editCurrency ? styles.cancelEditBtn + " " + styles.iconBtn : styles.displayNone}
													onClick={(e) => {
														e.stopPropagation();
														editCurrency && removeEditCurrency(editCurrency);
													}}
												>
													<CancelIcon />
												</IconButton>
											</Tooltip>
										) : (
											<>
												<Tooltip title="Изменить курсы" placement="top">
													<IconButton
														className={styles.addBtn}
														onClick={() => handleOpenEditCurrencyWindow(editCurrency ? editCurrency : currency)}
													>
														<ModeIcon />
													</IconButton>
												</Tooltip>
											</>
										)}
									</div>
								)}
								<p>
									<img
										src={`${basePath}/assets/images/Flags/GOLD.png`}
									/>
									<div>
										<p>{currency.code}</p>
										<p style={{ fontSize: "12px", fontWeight: 400 }}>{currency.description}</p>
									</div>
								</p>
								{
									editCurrency ? (
										<>
											<p style={{ justifySelf: "center", display: "flex", flexDirection: "column" }}>
												<p>
													{formatNumberRu(currency?.rates[0]?.buy.toFixed(2)) + (editCurrency && currency?.rates[0]?.buy !== editCurrency?.rates[0]?.buy ? " ➞ " + formatNumberRu(editCurrency?.rates[0]?.buy.toFixed(2)) : "")}
												</p>
											</p>
											<p style={{ justifySelf: "center", display: "flex", flexDirection: "column" }}>
												<p>
													{formatNumberRu(currency?.rates[0]?.sale.toFixed(2)) + (editCurrency && currency?.rates[0]?.sale !== editCurrency?.rates[0]?.sale ? " ➞ " + formatNumberRu(editCurrency?.rates[0]?.sale.toFixed(2)) : "")}
												</p>
											</p>
										</>
									) : (
										<>
											<p style={{ justifySelf: "center" }}>
												{formatNumberRu(currency?.rates[0]?.buy.toFixed(2))}
											</p>
											<p style={{ justifySelf: "center" }}>
												{formatNumberRu(currency?.rates[0]?.sale.toFixed(2))}
											</p>
										</>
									)
								}
								<p style={{ justifySelf: "center" }}>{index !== 0 && formatNumberRu(currency?.cash?.balance || 0)}</p>
								<p style={{ justifySelf: "center" }}>
									{currency.code === "GOLD1" && migRatesList?.find(migRate => migRate?.code === "GOL") && `${formatNumberRu(migRatesList?.find(migRate => migRate?.code === "GOL")?.buy || 0)} - ${formatNumberRu(migRatesList?.find(migRate => migRate?.code === "GOL")?.sell || 0)}`}
								</p>
							</div>
						);
					})}
				</div>
			}

			{
				editCurrencies.length > 0 &&
				<>

					<div
						style={{
							display: "grid",
							gridTemplateColumns: "300px 300px",
							gap: "10px",
							justifyContent: "center",
							marginTop: "10px"
						}}>
						<Button
							variant="contained"
							size="large"
							startIcon={<ClearIcon />}
							sx={{
								background: "#b70000",
								"&:hover": {
									background: "#7f0101",
								},
							}}
							onClick={clearEditCurrencies}
						>
							Отменить
						</Button>
						<Button
							variant="contained"
							size="large"
							startIcon={<DoneIcon />}
							sx={{
								background: "#037703",
								"&:hover": {
									background: "#005f00",
								},
							}}
							disabled={editCurrencies.length === 0}
							onClick={() => updateCurrencies(editCurrencies, department)}
						>
							Сохранить изменения
						</Button>
					</div>
				</>
			}
		</div >
	);
};