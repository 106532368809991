import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Footer from "./Footer";

const MainPageLayout = (Component: React.FC) => (
  <MainLayout>
    <Component />
  </MainLayout>
);

const mdTheme = createTheme({});

export const MainLayout = (props?: any) => {
  React.useEffect(() => {
    localStorage.removeItem("state");
  }, []);
  return (
    <ThemeProvider theme={mdTheme}>
      <Box>
        <CssBaseline />
        {props.children}
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default MainPageLayout;
