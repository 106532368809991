import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { ModalCashierActionProps } from './ModalCashierAction.props';

import { actions as clientInfoActions } from "@/redux/modules/ClientInfo";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PriceCheckIcon from "@mui/icons-material/PriceCheck";

import NumberFormat from 'react-number-format';

import InputMask from "react-input-mask";

import styles from "./modalCashierAction.module.css";
import ClientForm from '@/pages/ClientForm';
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { setError } from '@/redux/modules/department/departmentActions';

const wenv = process.env;

export const ModalCashierAction = ({
	openWindow,
	handleCloseWindow,
	rateExchange,
	handleChangeBuyAmount,
	handleChangeSaleAmount,
	clientInfo,
	setIsLoading,
	handleSubmitCurrencies,
	minLimitToIndivRate,
	isIndivRate,
	newIndivRate,
	handleSetIsIndivRate,
	handleSetNewIndivRate,
}: ModalCashierActionProps): JSX.Element => {
	const [step, setStep] = useState(0);
	const [maxNewIndivRate, setNewMaxIndivRate] = useState(+(rateExchange.rate).toFixed(2) + +(rateExchange.rate * 0.01).toFixed(2));
	const [isResident, setIsResident] = useState<boolean>(true);
	const [iinErr, setIinErr] = useState({
		error: false,
		errText: ""
	})

	const dispatch = useDispatch();

	useEffect(() => {
		if (rateExchange.buyCurrencyCode !== "KZT") {
			setNewMaxIndivRate(+(rateExchange.rate).toFixed(2) + +(rateExchange.rate * 0.01).toFixed(2));
		} else {
			setNewMaxIndivRate(+(rateExchange.rate).toFixed(2) - +(rateExchange.rate * 0.01).toFixed(2));
		}
	}, []);

	return (
		<Dialog
			open={openWindow}
			onClose={handleCloseWindow}
			maxWidth={step === 2 ? "lg" : "md"}
			fullWidth
		>
			{step === 0 && (
				<form onSubmit={(e) => {
					e.preventDefault();

					handleSubmitCurrencies(isIndivRate, newIndivRate) === true && setStep(1);
				}}
				>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							sx={{
								fontSize: "22px",
								fontWeight: "bold",
								color: "black",
								textAlign: "center",
							}}>
							{rateExchange?.buyCurrencyCode === "KZT"
								? "Продажа " + (rateExchange?.saleCurrencyCode.indexOf("GOLD") < 0 ? rateExchange?.saleCurrencyCode : "ЗОЛОТО " + rateExchange?.saleCurrencyCode.replace(/GOLD/g, "") + " гр")
								: "Покупка " + (rateExchange?.buyCurrencyCode.indexOf("GOLD") < 0 ? rateExchange?.buyCurrencyCode : "ЗОЛОТО " + rateExchange?.buyCurrencyCode.replace(/GOLD/g, "") + " гр")}
						</DialogContentText>
					</DialogContent>
					<div className={styles.container}>
						{
							// (rateExchange.buyCurrencyCode.indexOf("GOLD") < 0 && rateExchange.saleCurrencyCode.indexOf("GOLD") < 0)
							// &&
							(
								rateExchange?.buyCurrencyCode !== "KZT"
									? (
										<>
											<div className={styles.kurs_container}>
												<NumberFormat
													customInput={TextField}
													//Свойства TextField Mui5
													autoComplete={"off"}
													fullWidth
													value={rateExchange.buyAmount}
													label={`Мы покупаем: ${rateExchange?.buyCurrencyCode.indexOf("GOLD") < 0 ? rateExchange?.buyCurrencyCode : "ЗОЛОТО " + rateExchange?.buyCurrencyCode.replace(/GOLD/g, "") + " гр"}`}
													variant="outlined"
													className={styles.textField}
													onChange={(e) => {
														handleChangeBuyAmount(+e.target.value.replace(/\s/g, ""), newIndivRate);
													}}
													//Свойства NumberFormat
													thousandSeparator={" "}
													allowNegative={false}
													// decimalScale={2}
													fixedDecimalScale={true}
												/>
											</div>

											<div className={styles.kurs_container}>
												{isIndivRate
													? <div style={{ textAlign: "center" }}>
														<p><span>{rateExchange.rate.toFixed(2)}</span> тенге</p>
														<NumberFormat
															customInput={TextField}
															//Свойства TextField Mui5`
															autoComplete={"off"}
															fullWidth
															label={rateExchange.buyCurrencyCode.indexOf("GOLD") >= 0 || rateExchange.saleCurrencyCode.indexOf("GOLD") >= 0 ? "Индив. стоимость" : "Индив. курс"}
															value={newIndivRate}
															error={newIndivRate > maxNewIndivRate || newIndivRate <= rateExchange.rate}
															variant="outlined"
															sx={{
																borderRadius: "5px",
																marginTop: "10px",
																position: "relative",
																"& input": {
																	background: "white",
																	textAlign: "center",
																	fontSize: "20px",
																	// fontWeight: "bold",
																	padding: "4px 0",
																	borderRadius: "5px",
																},
																"& fieldset": {
																	textAlign: "center",
																},
																"& label": {
																	width: "100%",
																	textAlign: "center",
																	transformOrigin: "top center",
																	left: "-13px",
																},
																"& .Mui-disabled": {
																	color: "black !important",
																},
															}}
															onChange={(e) => handleSetNewIndivRate(+(+e.target.value.replace(/\s/g, "")).toFixed(2), maxNewIndivRate)}
															//Свойства NumberFormat
															thousandSeparator={" "}
															allowNegative={false}
															decimalScale={2}
															fixedDecimalScale={true}
														/>
													</div>
													: <p>{rateExchange.buyCurrencyCode.indexOf("GOLD") >= 0 || rateExchange.saleCurrencyCode.indexOf("GOLD") >= 0 ? "Стоимость" : "Курс"} <span>{formatNumberRu((+rateExchange.rate).toFixed(2))}</span> тенге</p>}
											</div>

											<div className={styles.kurs_container}>
												<NumberFormat
													customInput={TextField}
													//Свойства TextField Mui5
													autoComplete={"off"}
													fullWidth
													value={rateExchange.saleAmount}
													label={`Клиент получит: ${rateExchange?.saleCurrencyCode.indexOf("GOLD") < 0 ? rateExchange?.saleCurrencyCode : "ЗОЛОТО " + rateExchange?.saleCurrencyCode.replace(/GOLD/g, "") + " гр"}`}
													variant="outlined"
													className={styles.textField}
													onChange={(e) => {
														handleChangeSaleAmount(+e.target.value.replace(/\s/g, ""), newIndivRate);
													}}
													//Свойства NumberFormat
													thousandSeparator={" "}
													allowNegative={false}
													// decimalScale={2}
													fixedDecimalScale={true}
												/>
											</div>
										</>
									) : (
										<>
											<div className={styles.kurs_container}>
												<NumberFormat
													customInput={TextField}
													//Свойства TextField Mui5
													autoComplete={"off"}
													fullWidth
													value={rateExchange.saleAmount}
													label={`Мы продаем: ${rateExchange?.saleCurrencyCode}`}
													variant="outlined"
													className={styles.textField}
													onChange={(e) => {
														handleChangeSaleAmount(+e.target.value.replace(/\s/g, ""), newIndivRate);

													}}
													//Свойства NumberFormat
													thousandSeparator={" "}
													allowNegative={false}
													// decimalScale={2}
													fixedDecimalScale={true}
												/>
											</div>

											<div className={styles.kurs_container}>
												{isIndivRate
													? <div style={{ textAlign: "center" }}>
														<p><span>{rateExchange.rate.toFixed(2)}</span> тенге</p>
														<NumberFormat
															customInput={TextField}
															//Свойства TextField Mui5`
															autoComplete={"off"}
															fullWidth
															label={rateExchange.buyCurrencyCode.indexOf("GOLD") >= 0 || rateExchange.saleCurrencyCode.indexOf("GOLD") >= 0 ? "Индив. стоимость" : "Индив. курс"}
															value={newIndivRate}
															variant="outlined"
															error={newIndivRate < maxNewIndivRate || newIndivRate >= rateExchange.rate}
															sx={{
																borderRadius: "5px",
																marginTop: "10px",
																position: "relative",
																"& input": {
																	background: "white",
																	textAlign: "center",
																	fontSize: "20px",
																	// fontWeight: "bold",
																	padding: "4px 0",
																	borderRadius: "5px",
																},
																"& fieldset": {
																	textAlign: "center",
																},
																"& label": {
																	width: "100%",
																	textAlign: "center",
																	transformOrigin: "top center",
																	left: "-13px",
																},
																"& .Mui-disabled": {
																	color: "black !important",
																},
															}}
															onChange={(e) => handleSetNewIndivRate(+(+e.target.value.replace(/\s/g, "")).toFixed(2), maxNewIndivRate)}
															//Свойства NumberFormat
															thousandSeparator={" "}
															allowNegative={false}
															decimalScale={2}
															fixedDecimalScale={true}
														/>
													</div>
													: <p>{rateExchange.buyCurrencyCode.indexOf("GOLD") >= 0 || rateExchange.saleCurrencyCode.indexOf("GOLD") >= 0 ? "Стоимость" : "Курс"} <span>{formatNumberRu((+rateExchange.rate).toFixed(2))}</span> тенге</p>}
											</div>

											<div className={styles.kurs_container}>
												<NumberFormat
													customInput={TextField}
													//Свойства TextField Mui5
													autoComplete={"off"}
													fullWidth
													value={rateExchange.buyAmount}
													label={`Клиент предоставляет: ${rateExchange.buyCurrencyCode}`}
													variant="outlined"
													className={styles.textField}
													onChange={(e) => {
														handleChangeBuyAmount(+e.target.value.replace(/\s/g, ""), newIndivRate);

													}}
													//Свойства NumberFormat
													thousandSeparator={" "}
													allowNegative={false}
													// decimalScale={2}
													fixedDecimalScale={true}
												/>
											</div>
										</>
									))
						}
						<div>
							<Button
								variant={"contained"}
								sx={{
									background: "#005792",
									"&:hover": {
										backgroundColor: "#014675",
									},
								}}
								fullWidth
								type={'submit'}
								size="large"
								startIcon={<PriceCheckIcon />}
								disabled={+rateExchange.buyAmount === 0 || +rateExchange.saleAmount === 0 || (isIndivRate && (rateExchange.buyCurrencyCode !== "KZT" ? newIndivRate <= +rateExchange.rate || newIndivRate > maxNewIndivRate : newIndivRate >= +rateExchange.rate || newIndivRate < maxNewIndivRate))}
							>
								Провести
							</Button>
						</div>
						<div style={{ display: "flex", justifyContent: "end" }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={isIndivRate}
										disabled={rateExchange.buyCurrencyCode !== "KZT" ? +rateExchange.buyAmount < minLimitToIndivRate : +rateExchange.saleAmount < minLimitToIndivRate}
										onChange={handleSetIsIndivRate}
									/>
								}
								label={rateExchange.buyCurrencyCode.indexOf("GOLD") >= 0 || rateExchange.saleCurrencyCode.indexOf("GOLD") >= 0 ? "Индивидуальная стоимость" : "Индивидуальный курс"}
							/>
						</div>
					</div>
				</form>
			)}
			{step === 1 && (
				<form onSubmit={(e) => {
					e.preventDefault();

					setIsLoading(true);

					if (isResident && clientInfo.iin !== "000000000000") {
						axios
							.post(
								`${wenv.REACT_APP_API_URL}/Client/SearchClient?iin=${clientInfo.iin}`,
								{
									headers: {
										accept: "*/*",
									},
								}
							)

							.then((res: any) => {
								dispatch(
									clientInfoActions.updateClientInfo(
										(c) => c,
										(p) => {
											if (res.data.model.length !== 0) {
												p.firstName = res.data.model[0].firstName;
												p.middleName = res.data.model[0].middleName;
												p.lastName = res.data.model[0].lastName;
												p.iin = res.data.model[0].iin;
												p.phone = res.data.model[0].phone;
												p.citizen = res.data.model[0].citizen;
												p.birthday =
													new Date(+res.data.model[0].birthDay.slice(0, 4), +res.data.model[0].birthDay.slice(5, 7) - 1, +res.data.model[0].birthDay.slice(8, 10));
												p.doctype =
													res.data.model[0].documents[0].docType;
												p.docNumber =
													res.data.model[0].documents[0].docNumber;
												p.issuePlace =
													res.data.model[0].documents[0].issuePlace;
												p.issueDate =
													new Date(+res.data.model[0].documents[0].issueDate.slice(0, 4), +res.data.model[0].documents[0].issueDate.slice(5, 7) - 1, +res.data.model[0].documents[0].issueDate.slice(8, 10));
												p.validDate = new Date(+res.data.model[0].documents[0].validDate.slice(0, 4), +res.data.model[0].documents[0].validDate.slice(5, 7) - 1, +res.data.model[0].documents[0].validDate.slice(8, 10));
												p.photoImg = res.data.model[0].documents[0].content;
												p.signImg = res.data.model[0].documents[0].client;
												p.clientId = res.data.model[0].clientId;
												p.docId =
													res.data.model[0].documents[0].docId;
												p.adrId = res.data.model[0].clientAdress[0]?.adrId || null;
												p.country = res.data.model[0].clientAdress[0]?.country || "";
												p.region = res.data.model[0].clientAdress[0]?.region || "";
												p.locality = res.data.model[0].clientAdress[0]?.locality || "";
												p.street = res.data.model[0].clientAdress[0]?.street || "";
												p.number = res.data.model[0].clientAdress[0]?.number || "";
												p.flat = res.data.model[0].clientAdress[0]?.flat || "";
											} else {
												p.firstName = "";
												p.middleName = "";
												p.lastName = "";
												p.phone = "";
												p.citizen = "Республика Казахстан";
												p.birthday = "";
												p.doctype = "";
												p.docNumber = "";
												p.issuePlace = "";
												p.issueDate = "";
												p.validDate = "";
												p.photoImg = "";
												p.signImg = "";
												p.clientId = 0;
												p.adrId = 0;
												p.country = "";
												p.region = "";
												p.locality = "";
												p.street = "";
												p.number = "";
												p.flat = "";
											}

											return p;
										}
									)
								);
								setIsLoading(false);
								setStep(2);
							})
							.catch((e) => {
								console.error(e);
								setIsLoading(false);
							});
					} else {
						dispatch(
							clientInfoActions.updateClientInfo(
								(c) => c,
								(p) => {
									p.firstName = "";
									p.middleName = "";
									p.lastName = "";
									p.phone = "";
									p.citizen = "Республика Казахстан";
									p.birthday = "";
									p.doctype = "";
									p.docNumber = "";
									p.issuePlace = "";
									p.issueDate = "";
									p.validDate = "";
									p.photoImg = "";
									p.signImg = "";
									p.clientId = 0;
									p.adrId = 0;
									p.country = "";
									p.region = "";
									p.locality = "";
									p.street = "";
									p.number = "";
									p.flat = "";

									return p;
								}
							)
						);
						setStep(2);
						setIsLoading(false);
					}
				}}
				>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							sx={{
								fontSize: "22px",
								fontWeight: "bold",
								color: "black",
								textAlign: "center",
							}}>
							Поиск клиента
						</DialogContentText>
					</DialogContent>
					<DialogActions
						sx={{
							display: "flex",
							justifyContent: "center",
							padding: "0 100px 20px",
						}}>
						<Grid container spacing={2}>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								display="flex"
								justifyContent="center">
								<InputMask
									mask="9999 9999 9999"
									value={clientInfo.iin}
									onChange={(e) => {
										const string = e.target.value.replace(/[\s_]/g, "");
										dispatch(clientInfoActions.updateClientInfo(
											(c) => c.iin,
											() => string
										));

										if (string.length === 12 && isResident) {
											if (string === "000000000000") {
												setIinErr({
													error: true,
													errText: "Некорректный ИИН"
												});

												return;
											}

											const controlArr_1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
											const controlArr_2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
											const arrOfIinNumbers = [];
											let controlNumber = 0;

											for (let i = 0; i < 12; i++) {
												arrOfIinNumbers[i] = parseInt(string.substring(i, i + 1));
												if (i < 11) {
													controlNumber += arrOfIinNumbers[i] * controlArr_1[i];
												}
											}
											controlNumber = controlNumber % 11;

											if (controlNumber === 10) {
												controlNumber = 0;
												for (let i = 0; i < 11; i++) {
													controlNumber += arrOfIinNumbers[i] * controlArr_2[i];
												}
												controlNumber = controlNumber % 11;
											}
											if (controlNumber !== arrOfIinNumbers[11]) {
												setIinErr({
													error: true,
													errText: "Некорректный ИИН"
												});

												return;
											}
										}

										setIinErr({
											error: false,
											errText: ""
										});
									}}>
									<TextField
										autoComplete={"off"}
										sx={{
											background: "white",
											"& input": {
												textAlign: "center",
											},
											"& fieldset": {
												textAlign: "center",
											},
											"& label": {
												width: "100%",
												textAlign: "center",
												transformOrigin: "top center",
												left: "-13px",
											},
										}}
										error={iinErr.error}
										helperText={iinErr.errText}
										fullWidth
										id="outlined-basic"
										label="Введите ИИН клиента для продолжения"
										variant="outlined"
									/>
								</InputMask>
							</Grid>
							<Grid item xs={12} display="flex" justifyContent="center">
								<FormControlLabel
									control={
										<Checkbox
											checked={!isResident}
											onChange={() => {
												setIsResident(!isResident);
												if (isResident) {
													setIinErr({ error: false, errText: "" });
												} else if (clientInfo.iin.length === 12) {
													const controlArr_1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
													const controlArr_2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
													const arrOfIinNumbers = [];
													let controlNumber = 0;

													for (let i = 0; i < 12; i++) {
														arrOfIinNumbers[i] = parseInt(clientInfo.iin.substring(i, i + 1));
														if (i < 11) {
															controlNumber += arrOfIinNumbers[i] * controlArr_1[i];
														}
													}
													controlNumber = controlNumber % 11;

													if (controlNumber === 10) {
														controlNumber = 0;
														for (let i = 0; i < 11; i++) {
															controlNumber += arrOfIinNumbers[i] * controlArr_2[i];
														}
														controlNumber = controlNumber % 11;
													}
													if (controlNumber !== arrOfIinNumbers[11]) {
														setIinErr({
															error: true,
															errText: "Некорректный ИИН"
														});

														return;
													}
												}
											}}
										/>
									}
									label="Нерезидент"
								/>
							</Grid>
							<Grid
								item
								xs={12}
								display="flex"
								justifyContent="center"
							>
								<Button
									sx={{
										backgroundColor: "#fd5f00",
										fontWeight: "bold",
										"&:hover": {
											backgroundColor: "#005792",
											color: "white",
										},
									}}
									variant="contained"
									size="large"
									type={"submit"}
									disabled={isResident && (!(clientInfo.iin.length === 12) || iinErr.error)}
								>
									Продолжить
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</form>
			)}
			{step === 2 && (
				<>
					<ClientForm type={"cashier"} isResident={isResident} setIsResident={setIsResident} />
				</>
			)}
		</Dialog>
	);
};