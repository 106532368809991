import { api } from '@/api/Api';
import { CurrencyModel, MigRate } from '@/interface';
import { useEffect, useState } from 'react';
import pm from "@/core/helpers/ProcessManager";
import { useSelector } from 'react-redux';
import { selectActiveDepartment, selectNewCurrency } from '@/redux/modules/department/departmentSelectors';

export const useModalGoldActions = (
	editMode: boolean,
	handleGetFillialList: () => void,
	handleCloseWindow: () => void,
	migRate?: MigRate,
	goldCurrencies?: CurrencyModel[],
) => {
	const [gold1, setGold1] = useState(
		{
			code: "GOLD1",
			description: "Золотой слиток 1 грамм",
			country: "",
			spread: 0,
			margin: 0,
			isActive: true,
			depId: 0,
			fromParse: false,
			parseBuy: "",
			parseSale: "",
			parseSite: "",
			step: "",
			rates: {
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		}
	);
	const [gold5, setGold5] = useState(
		{
			code: "GOLD5",
			description: "Золотой слиток 5 грамм",
			country: "",
			spread: 0,
			margin: 0,
			isActive: true,
			depId: 0,
			fromParse: false,
			parseBuy: "",
			parseSale: "",
			parseSite: "",
			step: "",
			rates: {
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		}
	);
	const [gold10, setGold10] = useState(
		{
			code: "GOLD10",
			description: "Золотой слиток 10 грамм",
			country: "",
			spread: 0,
			margin: 0,
			isActive: true,
			depId: 0,
			fromParse: false,
			parseBuy: "",
			parseSale: "",
			parseSite: "",
			step: "",
			rates: {
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		}
	);
	const [gold20, setGold20] = useState(
		{
			code: "GOLD20",
			description: "Золотой слиток 20 грамм",
			country: "",
			spread: 0,
			margin: 0,
			isActive: true,
			depId: 0,
			fromParse: false,
			parseBuy: "",
			parseSale: "",
			parseSite: "",
			step: "",
			rates: {
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		}
	);
	const [gold50, setGold50] = useState(
		{
			code: "GOLD50",
			description: "Золотой слиток 50 грамм",
			country: "",
			spread: 0,
			margin: 0,
			isActive: true,
			depId: 0,
			fromParse: false,
			parseBuy: "",
			parseSale: "",
			parseSite: "",
			step: "",
			rates: {
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		}
	);
	const [gold100, setGold100] = useState(
		{
			code: "GOLD100",
			description: "Золотой слиток 100 грамм",
			country: "",
			spread: 0,
			margin: 0,
			isActive: true,
			depId: 0,
			fromParse: false,
			parseBuy: "",
			parseSale: "",
			parseSite: "",
			step: "",
			rates: {
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		}
	);

	const activeDepartment = useSelector(selectActiveDepartment);
	const newCurrency = useSelector(selectNewCurrency);

	useEffect(() => {
		loadMigRates();
	}, []);

	const changeGolds = (buyRate: number, saleRate: number) => {
		if (editMode) {
			setGold1((state) => ({
				...state,
				rates: {
					...state.rates,
					rateId: activeDepartment?.currencies.find(item => item.code === "GOLD1")?.rates[0].rateId,
					buy: +buyRate.toFixed(2),
					sale: +saleRate.toFixed(2),
				}
			}));
			setGold5((state) => ({
				...state,
				rates: {
					...state.rates,
					rateId: activeDepartment?.currencies.find(item => item.code === "GOLD5")?.rates[0].rateId,
					buy: +buyRate.toFixed(2) * 5,
					sale: +saleRate.toFixed(2) * 5,
				}
			}));
			setGold10((state) => ({
				...state,
				rates: {
					...state.rates,
					rateId: activeDepartment?.currencies.find(item => item.code === "GOLD10")?.rates[0].rateId,
					buy: +buyRate.toFixed(2) * 10,
					sale: +saleRate.toFixed(2) * 10,
				}
			}));
			setGold20((state) => ({
				...state,
				rates: {
					...state.rates,
					rateId: activeDepartment?.currencies.find(item => item.code === "GOLD20")?.rates[0].rateId,
					buy: +buyRate.toFixed(2) * 20,
					sale: +saleRate.toFixed(2) * 20,
				}
			}));
			setGold50((state) => ({
				...state,
				rates: {
					...state.rates,
					rateId: activeDepartment?.currencies.find(item => item.code === "GOLD50")?.rates[0].rateId,
					buy: +buyRate.toFixed(2) * 50,
					sale: +saleRate.toFixed(2) * 50,
				}
			}));
			setGold100((state) => ({
				...state,
				rates: {
					...state.rates,
					rateId: activeDepartment?.currencies.find(item => item.code === "GOLD100")?.rates[0].rateId,
					buy: +buyRate.toFixed(2) * 100,
					sale: +saleRate.toFixed(2) * 100,
				}
			}));
		} else {
			setGold1((state) => ({
				...state,
				rates: {
					...state.rates,
					buy: +buyRate.toFixed(2),
					sale: +saleRate.toFixed(2),
				}
			}));
			setGold5((state) => ({
				...state,
				rates: {
					...state.rates,
					buy: +buyRate.toFixed(2) * 5,
					sale: +saleRate.toFixed(2) * 5,
				}
			}));
			setGold10((state) => ({
				...state,
				rates: {
					...state.rates,
					buy: +buyRate.toFixed(2) * 10,
					sale: +saleRate.toFixed(2) * 10,
				}
			}));
			setGold20((state) => ({
				...state,
				rates: {
					...state.rates,
					buy: +buyRate.toFixed(2) * 20,
					sale: +saleRate.toFixed(2) * 20,
				}
			}));
			setGold50((state) => ({
				...state,
				rates: {
					...state.rates,
					buy: +buyRate.toFixed(2) * 50,
					sale: +saleRate.toFixed(2) * 50,
				}
			}));
			setGold100((state) => ({
				...state,
				rates: {
					...state.rates,
					buy: +buyRate.toFixed(2) * 100,
					sale: +saleRate.toFixed(2) * 100,
				}
			}));
		}
	};

	const loadMigRates = () => {
		if (editMode) {
			changeGolds(+newCurrency.rates[0].buy, +newCurrency.rates[0].sale);
		} else {
			if (migRate) {
				if (!activeDepartment?.currencies?.find(cur => cur.code.match(/GOLD1/gi))?.rates[0].buy) {
					changeGolds(+migRate.buy, +migRate.sell);
				} else {
					changeGolds(activeDepartment?.currencies?.find(cur => cur.code.match(/GOLD/gi))?.rates[0].buy || 0, activeDepartment?.currencies?.find(cur => cur.code.match(/GOLD/gi))?.rates[0].sale || 0);
				}
			}
		}
	};

	const validateAddCurrency = (currency: any) => {
		let boolean = false;

		if (+currency.rates.buy >= +currency.rates.sale) {
			boolean = true;
		}

		return boolean;
	};

	const addGold = async (gold: any) => {
		const currency: any = gold;
		const rates = { ...currency.rates };
		const copyCurrency = { ...currency };
		delete copyCurrency.rates;
		delete copyCurrency.cash;
		delete copyCurrency.step;

		await api.currency.addCurrency({ ...copyCurrency, depId: activeDepartment?.id })
			.then(async (res) => {
				if (res) {
					await api.rate.addRate({ ...rates, currencyId: res.value.currencyId });

					await handleGetFillialList();
					handleCloseWindow();
				}
			})
			.catch(() => pm.openSnackBar('Произошла ошибка: не удалось добавить баланс'));
	};

	const handleAddCurrency = () => {
		pm.openBackDrop();

		Promise.all([addGold(gold1), addGold(gold5), addGold(gold10), addGold(gold20), addGold(gold50), addGold(gold100)])
			.finally(pm.closeBackDrop);

	};

	return {
		gold1,
		gold5,
		gold10,
		gold20,
		gold50,
		gold100,
		changeGolds,
		validateAddCurrency,
		handleAddCurrency,
		activeDepartment,
	};
};