export type Regions = 'Алматы' | 'Астана';

export interface DepartmentModel {
	id: number
	name: string
	code: string
	address: string
	bin: string
	adviser: string
	isActive: number
	kassa: KassaModel[]
	currencies: CurrencyModel[]
	balance: { balance: number, currencyCode: string }[]
}

export interface KassaModel {
	id: number
	name: string
	code: string
	adviser: string
	cash: CashModel[]
	depId: number
}

export interface CurrencyModel {
	currencyId?: number
	code: string
	description: string
	country: string
	spread: number
	margin: number
	isActive: boolean
	fromParse: boolean
	parseSite: string
	parseSale: string
	parseBuy: string
	step: string
	depId?: number
	cash?: CashModel
	rates: RateModel[]
}

export interface RateModel {
	rateId?: number
	buy: number
	sale: number
	limitFrom: number
	limitTo: number
	initiator: string
	currencyId?: number
}

export interface CashModel {
	cashId: number
	reserved: number
	balance: number
	currencyCode: string
	kassaId: number
	kassa?: KassaModel
	currencyId: number
	currency?: CurrencyModel
	cashLogs: []
}

export interface NationalBankRate {
	description: string;
	change: string;
	fullname: string;
	index: string;
	quant: string;
	title: string;
}

export interface MigRate {
	buy: string;
	code: string;
	description: string;
	sell: string;
}

export interface BccRate {
	currencyCode: string;
	sellRate: string;
	buyRate: string;
}

export interface KaseRate {
	changes: string;
	code: string;
	description: string;
	price: string;
}

export interface NationalBankRate {
	description: string;
	change: string;
	fullname: string;
	index: string;
	quant: string;
	title: string;
}

export const currencyArray = [
	{
		code: "GOLD",
		description: "Золото"
	},
	{
		code: "RUB",
		description: "Российский рубль"
	},
	{
		code: "USD",
		description: "Доллар США"
	},
	{
		code: "EUR",
		description: "Евро"
	},
	{
		code: "KGS",
		description: "Кыргызский сом"
	},
	{
		code: "GBP",
		description: "Фунт стерлингов"
	},
	{
		code: "CNY",
		description: "Китайский юань"
	},
	{
		code: "CHF",
		description: "Швейцарский франк"
	},
	{
		code: "HKD",
		description: "Гонконгский доллар"
	},
	{
		code: "GEL",
		description: "Грузинский лари"
	},
	{
		code: "AED",
		description: "Дирхам ОАЭ"
	},
	{
		code: "INR",
		description: "Индийская рупия"
	},
	{
		code: "CAD",
		description: "Канадский доллар"
	},
	{
		code: "MYR",
		description: "Малайзийский ринггит"
	},
	{
		code: "PLN",
		description: "Польский злотый"
	},
	{
		code: "THB",
		description: "Тайский бат"
	},
	{
		code: "TRY",
		description: "Турецкая лира"
	},
	{
		code: "UZS",
		description: "Узбекский сум"
	},
	{
		code: "UAH",
		description: "Украинская гривна"
	},
	{
		code: "CZK",
		description: "Чешская крона"
	},
	{
		code: "ILS",
		description: "Новый израильский шекель"
	},
	{
		code: "KRW",
		description: "Южнокорейская вона"
	},
	{
		code: "JPY",
		description: "Японская иена"
	},
	{
		code: "AUD",
		description: "Австралийский доллар"
	},
	{
		code: "NZD",
		description: "Новозеландский доллар"
	},
	{
		code: "SGD",
		description: "Сингапурский доллар"
	},
	{
		code: "BRL",
		description: "Бразильский реал"
	},
	{
		code: "ZAR",
		description: "Южноафриканский рэнд"
	},
	{
		code: "MXN",
		description: "Мексиканское песо"
	},
	{
		code: "ARS",
		description: "Аргентинское песо"
	},
	{
		code: "SEK",
		description: "Шведская крона"
	},
	{
		code: "NOK",
		description: "Норвежская крона"
	},
	{
		code: "DKK",
		description: "Датская крона"
	},
	{
		code: "HUF",
		description: "Венгерский форинт"
	},
	{
		code: "IDR",
		description: "Индонезийская рупия"
	},
	{
		code: "PHP",
		description: "Филиппинское песо"
	},
	{
		code: "VND",
		description: "Вьетнамский донг"
	},
	{
		code: "EGP",
		description: "Египетский фунт"
	},
	{
		code: "MAD",
		description: "Марокканский дирхам"
	},
	{
		code: "NGN",
		description: "Нигерийская найра"
	},
	{
		code: "PKR",
		description: "Пакистанская рупия"
	},
	{
		code: "BDT",
		description: "Бангладешская така"
	},
	{
		code: "LKR",
		description: "Шри-ланкийская рупия"
	},
	{
		code: "CLP",
		description: "Чилийское песо"
	},
	{
		code: "COP",
		description: "Колумбийское песо"
	},
	{
		code: "PEN",
		description: "Перуанский соль"
	},
	{
		code: "SAR",
		description: "Саудовский риял"
	},
	{
		code: "ALL",
		description: "Албанский лек"
	},
	{
		code: "DZD",
		description: "Алжирский динар"
	},
	{
		code: "AOA",
		description: "Ангольская кванза"
	},
	{
		code: "AMD",
		description: "Армянский драм"
	},
	{
		code: "AWG",
		description: "Арубанский флорин"
	},
	{
		code: "AZN",
		description: "Азербайджанский манат"
	},
	{
		code: "BHD",
		description: "Бахрейнский динар"
	},
	{
		code: "BBD",
		description: "Барбадосский доллар"
	},
	{
		code: "BZD",
		description: "Белизский доллар"
	},
	{
		code: "BMD",
		description: "Бермудский доллар"
	},
	{
		code: "BTN",
		description: "Бутанский нгултрум"
	},
	{
		code: "BOB",
		description: "Боливийский боливиано"
	},
	{
		code: "BAM",
		description: "Боснийская марка"
	},
	{
		code: "BWP",
		description: "Ботсванская пула"
	},
	{
		code: "BND",
		description: "Брунейский доллар"
	},
	{
		code: "BGN",
		description: "Болгарский лев"
	},
	{
		code: "BIF",
		description: "Бурундийский франк"
	},
	{
		code: "CVE",
		description: "Эскудо Кабо-Верде"
	},
	{
		code: "KHR",
		description: "Камбоджийский риель"
	},
	{
		code: "XAF",
		description: "Франк КФА (Центральная Африка)"
	},
	{
		code: "XOF",
		description: "Франк КФА (Западная Африка)"
	},
	{
		code: "KMF",
		description: "Коморский франк"
	},
	{
		code: "CDF",
		description: "Конголезский франк"
	},
	{
		code: "CRC",
		description: "Коста-риканский колон"
	},
	{
		code: "CUP",
		description: "Кубинское песо"
	},
	{
		code: "DOP",
		description: "Доминиканское песо"
	},
	{
		code: "ETB",
		description: "Эфиопский быр"
	},
	{
		code: "FJD",
		description: "Фиджийский доллар"
	},
	{
		code: "GMD",
		description: "Гамбийский даласи"
	},
	{
		code: "GHS",
		description: "Ганский седи"
	},
	{
		code: "GTQ",
		description: "Гватемальский кетсаль"
	},
	{
		code: "GNF",
		description: "Гвинейский франк"
	},
	{
		code: "GYD",
		description: "Гайанский доллар"
	},
	{
		code: "HTG",
		description: "Гаитянский гурд"
	},
	{
		code: "HNL",
		description: "Гондурасская лемпира"
	},
	{
		code: "ISK",
		description: "Исландская крона"
	},
	{
		code: "IQD",
		description: "Иракский динар"
	},
	{
		code: "JMD",
		description: "Ямайский доллар"
	},
	{
		code: "JOD",
		description: "Иорданский динар"
	},
	{
		code: "KZT",
		description: "Казахстанский тенге"
	},
	{
		code: "KES",
		description: "Кенийский шиллинг"
	},
	{
		code: "KWD",
		description: "Кувейтский динар"
	},
	{
		code: "LAK",
		description: "Лаосский кип"
	},
	{
		code: "LBP",
		description: "Ливанский фунт"
	},
	{
		code: "LSL",
		description: "Лесотский лоти"
	},
	{
		code: "LRD",
		description: "Либерийский доллар"
	},
	{
		code: "LYD",
		description: "Ливийский динар"
	},
	{
		code: "MOP",
		description: "Макао патака"
	},
	{
		code: "MWK",
		description: "Малавийская квача"
	},
	{
		code: "MVR",
		description: "Мальдивская руфия"
	},
	{
		code: "MRO",
		description: "Мавританская угия"
	},
	{
		code: "MUR",
		description: "Маврикийская рупия"
	},
	{
		code: "MNT",
		description: "Монгольский тугрик"
	},
	{
		code: "MZN",
		description: "Мозамбикский метикал"
	},
	{
		code: "NAD",
		description: "Намибийский доллар"
	},
	{
		code: "NPR",
		description: "Непальская рупия"
	},
	{
		code: "NIO",
		description: "Никарагуанская кордоба"
	},
	{
		code: "OMR",
		description: "Оманский риал"
	},
	{
		code: "PGK",
		description: "Кина Папуа-Новой Гвинеи"
	},
	{
		code: "PYG",
		description: "Парагвайский гуарани"
	},
	{
		code: "QAR",
		description: "Катарский риал"
	},
	{
		code: "RON",
		description: "Румынский лей"
	},
	{
		code: "RWF",
		description: "Руандийский франк"
	},
	{
		code: "WST",
		description: "Самоанская тала"
	},
	{
		code: "STD",
		description: "Добра Сан-Томе и Принсипи"
	},
	{
		code: "SVC",
		description: "Сальвадорский колон"
	},
	{
		code: "SCR",
		description: "Сейшельская рупия"
	},
	{
		code: "SLL",
		description: "Сьерра-леонский леоне"
	},
	{
		code: "SOS",
		description: "Сомалийский шиллинг"
	},
	{
		code: "SZL",
		description: "Свазилендский лилангени"
	},
	{
		code: "SYP",
		description: "Сирийский фунт"
	},
	{
		code: "TJS",
		description: "Таджикский сомони"
	},
	{
		code: "TZS",
		description: "Танзанийский шиллинг"
	},
	{
		code: "TOP",
		description: "Паанга Тонга"
	},
	{
		code: "TTD",
		description: "Доллар Тринидада и Тобаго"
	},
	{
		code: "TND",
		description: "Тунисский динар"
	},
	{
		code: "TMT",
		description: "Туркменский манат"
	},
	{
		code: "UGX",
		description: "Угандийский шиллинг"
	},
	{
		code: "UYU",
		description: "Уругвайское песо"
	},
	{
		code: "VUV",
		description: "Вануату вату"
	},
	{
		code: "VEF",
		description: "Венесуэльский боливар"
	},
	{
		code: "YER",
		description: "Йеменский риал"
	},
	{
		code: "ZMW",
		description: "Замбийская квача"
	},
	{
		code: "ZWL",
		description: "Зимбабвийский доллар"
	}
];
