import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import { useRef } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import styles from './styles.module.css';
import { useSelector } from "react-redux";
import { selectActiveDepartmentLanding } from '@/redux/modules/department/departmentSelectors';

const basePath = process.env.PUBLIC_URL;

const departmentsList = [
  {
    address: "111111111111",
    tel: "+7 (708) 573 69 29",
    twogis: "https://2gis.kz/almaty/firm/70000001089043648/76.934037%2C43.249469?m=76.934037%2C43.249469%2F16",
    coordinates: [43.249469, 76.934037]
  },
  {
    address: "ТЦ ГрандПарк",
    tel: "+7 (705) 908 90 73",
    twogis: "https://2gis.kz/almaty/firm/70000001080960623/76.850845%2C43.237542?m=76.850845%2C43.237542%2F16",
    coordinates: [43.237542, 76.850845]
  },
  {
    address: "Аэропорт Астана",
    tel: "+7 (707) 159 18 88",
    twogis: "https://2gis.kz/astana/firm/70000001084480118/71.461165%2C51.027207?m=71.462563%2C51.027075%2F17.85",
    coordinates: [51.027207, 71.461165]
  },
  {
    address: "ТЦ Ханшатыр",
    tel: "+7 (707) 895 88 86",
    twogis: "https://2gis.kz/astana/firm/70000001083470007/71.404164%2C51.132516?m=71.404164%2C51.132516%2F17.85",
    coordinates: [51.132516, 71.404164]
  },
  {
    address: "ТЦ Сарыарка",
    tel: "+7 (707) 460 88 38",
    twogis: "https://2gis.kz/astana/firm/70000001062184974/71.410372%2C51.138198?m=71.410372%2C51.138198%2F17.85",
    coordinates: [51.138198, 71.410372]
  },
];

const Footer = () => {
  const map: any = useRef();

  const activeDepartment = useSelector(selectActiveDepartmentLanding);
  const targetDepartment = departmentsList.find(dep => dep.address === activeDepartment?.address) || departmentsList[2];

  return (
    <div className={styles.footer_container}>
      <div>
        <img src={`${basePath}/LOGOSVG1.webp`} />
      </div>
      <div>
        <div>
          <PhoneInTalkIcon />
          <div>
            <a href={targetDepartment.tel}>{targetDepartment.tel}</a>
          </div>
        </div>
        <div>
          <CalendarMonthIcon />
          <div style={{ display: 'flex', gap: '10px' }}>
            <p>ПН - ВС</p>
            <p>с 9:00 до 20:00</p>
          </div>
        </div>
        <div>
          <LocationOnIcon />
          <a href={targetDepartment?.twogis} target='_blank' rel="noreferrer">г.{activeDepartment?.code}, {activeDepartment?.address}</a>
        </div>
        <div className={styles.mapBlock}>
          <YMaps
            query={{
              apikey: "34a3447b-70be-4734-b74f-0268eefca581",
              ns: "use-load-option",
              load: "Map,Placemark,control.ZoomControl,control.FullscreenControl",
            }}>
            <Map
              instanceRef={map}
              width={"260px"}
              height={"200px"}
              state={{
                center: targetDepartment?.coordinates,
                zoom: 16,
                controls: ["zoomControl", "fullscreenControl"],
              }}>
              <Placemark geometry={targetDepartment?.coordinates} />
            </Map>
          </YMaps>
        </div>
      </div>
      {/* <div>
            <a
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              target="_blank"
              href={basePath + "/assets/documents/agent_contract.pdf"}
            >
              Договор агентского соглашения
            </a>
          </div> */}
    </div>
  );
};

export default Footer;
