import { DepartmentModel } from '@/interface';
import { InitialStateProps } from './departmentReducer';

export const selectDepartmentStatus = (state: { departmentReducer: InitialStateProps }) => state.departmentReducer.status;
export const selectDepartmentList = (state: { departmentReducer: InitialStateProps }) => state.departmentReducer.departmentList;
export const selectDepartmentListLanding = (state: { departmentReducer: { departmentList: any } }) => state.departmentReducer.departmentList;
export const selectActiveDepartment = (state: { departmentReducer: InitialStateProps }) => state.departmentReducer.departmentList.find(department => department.id === state.departmentReducer.activeDepartmentId);
export const selectActiveDepartmentLanding = (state: { departmentReducer: InitialStateProps }) => state.departmentReducer.departmentList.find(department => department.address === state.departmentReducer.activeDepartmentAddress);
export const selectActiveCurrency = (state: { departmentReducer: InitialStateProps }, department?: DepartmentModel) => department?.currencies.find(currency => currency.currencyId === state.departmentReducer.activeCurrencyId);
export const selectActiveRegion = (state: { departmentReducer: InitialStateProps }) => state.departmentReducer.activeRegion;
export const selectRegions = (state: { departmentReducer: InitialStateProps }) => state.departmentReducer.regions;
export const selectNewCurrency = (state: { departmentReducer: InitialStateProps }) => state.departmentReducer.newCurrency;
export const selectKassaIsOpen = (state: { departmentReducer: InitialStateProps }) => state.departmentReducer.kassaIsOpen;
export const selectActiveKassa = (state: { departmentReducer: InitialStateProps }, activeDepartment?: DepartmentModel) => activeDepartment?.kassa.find(k => k.id === state.departmentReducer.activeKassaId);