import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import { ProcessReducer } from "@/redux/modules/Process";
import { useSelector } from "react-redux";
import PopupSnackBar from "./PopupSnackBar";
import PopupBackDrop from "./PopupBackDrop";
import pm from "@/core/helpers/ProcessManager";
import PopupConfirm from "./PopupConfirm";
import QueueMenu from "./QueueMenu";
import processManager from "@/core/helpers/ProcessManager";

const authorizedLayout = (Component: React.FC) => (
  <MainLayout>
    <Component />
  </MainLayout>
);

const mdTheme = createTheme({});

export const MainLayout = (props?: any) => {
  const process: ProcessReducer = useSelector((state: any) => state.process);

  const { isLoading, snackBar, backdrop, confirm } = process;

  const [timerId, setTimerId] = React.useState<any>();

  const fetchData = () => {
    processManager.fetchQueueData();
  };

  React.useEffect(() => {
    localStorage.removeItem("state");

    if (timerId) {
      clearInterval(timerId);
    }
    fetchData();
    const id = setInterval(fetchData, 30000);
    setTimerId(id);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <PopupSnackBar onClose={() => pm.closeSnackBar()} {...snackBar} />
        <PopupBackDrop {...backdrop} />
        <PopupConfirm
          handleDisagreeOrClose={() => pm.closeSnackBar()}
          {...confirm}
        />
        <CssBaseline />
        <Header isLoading={isLoading} />
        <LeftMenu />
        <QueueMenu />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}>
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {props.children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default authorizedLayout;
