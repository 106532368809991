import { api } from '@/api/Api';
import { BccRate, CurrencyModel, DepartmentModel, MigRate } from '@/interface';
import { loadDepartments, resetNewCurrency, setActiveCurrencyId, setActiveDepartmentId, setNewCurrency } from '@/redux/modules/department/departmentActions';
import { InitialStateProps } from '@/redux/modules/department/departmentReducer';
import { selectActiveCurrency, selectActiveDepartment, selectDepartmentStatus, selectNewCurrency, selectRegions } from '@/redux/modules/department/departmentSelectors';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pm from "@/core/helpers/ProcessManager";
import { UpdateRateManyVariables } from '@/api/RateController';
import { Role } from '@/api/CamundaController';

export type OpenWindowType = "selectCurrency" | "addCurrency" | "deleteCurrency" | "editCurrency" | null;

export const useTreasurePage = () => {
	const [viewOptomRate, setViewOptomRate] = useState(false);
	const [openWindow, setOpenWindow] = useState<OpenWindowType>(null);
	const [editCurrencies, setEditCurrencies] = useState<CurrencyModel[]>([]);
	const [migRatesList, setMigRatesList] = useState<MigRate[]>([]);
	const [bccRatesList, setBccRatesList] = useState<BccRate[]>([]);

	const dispatch = useDispatch();

	const departmentStatus = useSelector(selectDepartmentStatus);
	const activeDepartment = useSelector(selectActiveDepartment);
	const activeCurrency = useSelector((state: { departmentReducer: InitialStateProps }) => selectActiveCurrency(state, activeDepartment));
	const newCurrency = useSelector(selectNewCurrency);

	const regions = useSelector(selectRegions);

	const roles: Role[] = useSelector((state: any) => state?.header?.userContext?.user?.roles || {});
	const role = useMemo(() => {
		let code: number | null = null;
		for (const role of roles) {
			if (role.code === "ADMIN") {
				code = null;
				break;
			} else if (role.code === "Cashier_Almaty_Dostyk") {
				code = 6;
				break;
			} else if (role.code === "Cashier_Astana_Aeroport") {
				code = 9;
				break;
			} else if (role.code === "Cashier_Almaty_GrandPark") {
				code = 8;
				break;
			} else if (role.code === "Cashier_Astana_Saryarka") {
				code = 10;
				break;
			} else if (role.code === "Cashier_Astana_Hanshatyr") {
				code = 7;
				break;
			} else if (role.code === "Cashier_Almaty_Rixos") {
				code = 11;
				break;
			}
		}
		return code;
	}, []);

	useEffect(() => {
		handleGetDepartmentList();
		loadMigAndBccRates();

		pm.closeBackDrop();
	}, []);

	const loadMigAndBccRates = async () => {
		await api.parsing.loadMigRates().then((res: MigRate[]) => {
			setMigRatesList(res);
		});
		await api.parsing.loadBccRates().then((res: BccRate[]) => {
			setBccRatesList(res);
		});
	};

	const handleChangeNewCurrency = (currency: CurrencyModel) => {
		dispatch(setNewCurrency(currency));
	};

	const handleGetDepartmentList = async () => {
		await dispatch(loadDepartments());
	};

	const handleOpenSelectNewCurrencyWindow = (id: string | number) => {
		setOpenWindow("selectCurrency");
		dispatch(setActiveDepartmentId(id));
	};

	const handleOpenAddCurrencyWindow = () => {
		dispatch(setNewCurrency({ ...newCurrency, depId: +(activeDepartment?.id || 0) }));
		setOpenWindow("addCurrency");
	};

	const handleOpenDeleteCurrencyWindow = (currencyId: string | number, departmentId: string | number) => {
		setOpenWindow("deleteCurrency");
		dispatch(setActiveCurrencyId(departmentId, currencyId));
	};

	const handleCloseWindow = () => {
		setOpenWindow(null);
		dispatch(resetNewCurrency());
	};

	const handleDeleteCurrency = async (currencyId: string | number) => {
		pm.openBackDrop();

		await api.currency.deleteCurrency(currencyId)
			.then((res: any) => {
				if (res) {
					handleCloseWindow();
					handleGetDepartmentList();
				} else {
					pm.openSnackBar("Произошла ошибка", {
						severity: "error",
					});
				}
			})
			.catch(() => pm.openSnackBar('Произошла ошибка: не удалось удалить валюту'))
			.finally(() => pm.closeBackDrop());
	};

	const handleOpenEditCurrencyWindow = (currency: CurrencyModel) => {
		setOpenWindow('editCurrency');
		currency?.rates.sort(a => a.limitFrom === 0 ? -1 : 1);
		dispatch(setNewCurrency(currency));
	};

	const clearEditCurrencies = () => {
		setEditCurrencies([]);
	};

	const updateCurrencies = (currencies: CurrencyModel[], department: DepartmentModel) => {
		console.log(currencies)
		pm.openBackDrop();
		const rates: UpdateRateManyVariables[] = [];
		Promise.all([
			...currencies.map(currency => {
				api.currency.updateCurrency(currency)
					.then(res => {
						if (!res) {
							return pm.openSnackBar("Произошла ошибка", { severity: "error" });
						}
					})
					.catch(() => pm.openSnackBar("Произошла ошибка: не удалось обновить валюту", { severity: "error" }));

				currency.rates.map(rate => {
					rates.push({
						depId: department.id,
						id: rate.rateId || 0,
						buy: rate.buy.toFixed(2),
						sale: rate.sale.toFixed(2),
						limitFrom: rate.limitFrom,
						limitTo: rate.limitTo,
						initiator: rate.initiator
					});
				});
			}),
			api.rate.updateRateMany(rates)
				.then(res => {
					if (res) {
						dispatch(loadDepartments());
						clearEditCurrencies();
						pm.closeBackDrop();
					}
				})
				.catch(() => pm.openSnackBar("Произошла ошибка: не удалось обновить курсы", { severity: "error" }))
		]).finally(() => pm.closeBackDrop());
	};

	const addEditCurrencies = (currency: CurrencyModel) => {
		currency.rates.sort(a => a.limitFrom === 0 ? -1 : 1);
		setEditCurrencies((state: CurrencyModel[]) => {
			const filtredState = state.filter(c => c.code !== currency.code);
			return [...filtredState, currency];
		});
		handleCloseWindow();
	};

	const removeEditCurrency = (currency: CurrencyModel) => {
		setEditCurrencies((state: CurrencyModel[]) => state.filter(c => c.code !== currency.code));
	};

	return {
		activeDepartment,
		viewOptomRate,
		setViewOptomRate,
		handleOpenAddCurrencyWindow,
		handleOpenSelectNewCurrencyWindow,
		handleOpenDeleteCurrencyWindow,
		clearEditCurrencies,
		editCurrencies,
		updateCurrencies,
		handleOpenEditCurrencyWindow,
		removeEditCurrency,
		migRatesList,
		bccRatesList,
		departmentStatus,
		openWindow,
		handleCloseWindow,
		activeCurrency,
		newCurrency,
		handleChangeNewCurrency,
		regions,
		handleGetDepartmentList,
		addEditCurrencies,
		handleDeleteCurrency,
		role
	};
};