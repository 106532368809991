import React from "react";

import NumberFormat from 'react-number-format';

import { ModalCurrencyActionProps } from './ModalCurrencyAction.props';

import { Button, FormControl, FormControlLabel, Switch, TextField } from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import DoneIcon from "@mui/icons-material/Done";

import { CurrencyModel } from '@/interface';
import { formatNumberRu } from '@/core/helpers/PageHelpers';

import styles from "./modalCurrencyAction.module.css";
import { ModalWindow } from '@/components';
import { useModalCurrencyActions } from './useModalCurrencyAction';
import { RateModel } from '@/interface';

export const ModalCurrencyAction = ({
	openWindow,
	handleCloseWindow,
	activeDepartment,
	newCurrency,
	handleGetDepartmentList,
	addEditCurrencies,
	migRate,
	bccRate,
}: ModalCurrencyActionProps): JSX.Element => {
	const {
		isRatesByParse,
		setIsRatesByParse,
		currencyNotByParse,
		setCurrencyNotByParse,
		currencyByParse,
		setCurrencyByParse,
		validateAddCurrency,
		handleAddCurrency,
	} = useModalCurrencyActions(newCurrency, openWindow === "editCurrency", handleGetDepartmentList, handleCloseWindow, migRate);

	return (
		<ModalWindow
			open={openWindow === "addCurrency" || openWindow === "editCurrency"}
			title={`г.${activeDepartment?.code}, ${activeDepartment?.address}`}
			onClose={handleCloseWindow}
			maxWidth="md"
			fullWidth
			scroll="body"
			sx={{
				zIndex: (theme) => theme.zIndex.drawer + 1,
				"& .MuiPaper-root": {
					padding: '20px',
				},
				'& h3': {
					textAlign: 'center',
					marginBottom: '10px'
				}
			}}
		>
			<div className={styles.container}>
				<div className={styles.currency_title}>
					<p>{openWindow === "editCurrency" ? "Изменение валюты:" : "Добавление валюты:"}</p>
					<h2>{newCurrency?.code}</h2>
					<p style={{ textAlign: "center" }}>
						{migRate?.description}
					</p>
				</div>


				<div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
					<div className={styles.competitor_block}>
						<h2>БЦК</h2>
						{bccRate ? (
							<>
								<div className={styles.rate_block}>
									<div className={styles.rate_text}>
										<p>Покупка</p>
										<div>{formatNumberRu(bccRate?.sellRate)}</div>
									</div>
									<SyncAltIcon />
									<div className={styles.rate_text}>
										<p>Продажа</p>
										<div>{formatNumberRu(bccRate?.buyRate)}</div>
									</div>
								</div>
							</>
						) : (
							<div className={styles.rate_block}>
								<p>Курс не найден</p>
							</div>
						)}
					</div>

					<div className={styles.competitor_block}>
						<h2>МИГ</h2>
						{migRate ? (
							<>
								<div className={styles.rate_block}>
									<div className={styles.rate_text}>
										<p>Покупка</p>
										<div>{formatNumberRu(migRate?.buy)}</div>
									</div>
									<SyncAltIcon />
									<div className={styles.rate_text}>
										<p>Продажа</p>
										<div>{formatNumberRu(migRate?.sell)}</div>
									</div>
								</div>
							</>
						) : (
							<div className={styles.rate_block}>
								<p>Курс не найден</p>
							</div>
						)}
					</div>
				</div>

				{
					activeDepartment?.code === "Алматы" &&
					<div style={{ display: "flex", justifyContent: "center" }}>
						<FormControl component="fieldset" variant="standard">
							<FormControlLabel
								control={
									<Switch
										checked={isRatesByParse}
										onChange={(e) => setIsRatesByParse(e.target.checked)}
									/>
								}
								label="Автообновление по МИГ-у"
							/>
						</FormControl>
					</div>
				}

				<div className={styles.currency_calc} style={isRatesByParse && openWindow === "editCurrency" ? { gridTemplateColumns: "1fr 1fr 1fr 1fr" } : {}}>
					<h3>Порог</h3>
					<h3>Курс покупки</h3>
					<h3>Курс продажи</h3>

					{!isRatesByParse &&
						currencyNotByParse?.rates?.map((rate: RateModel, index: number) => (
							<React.Fragment key={rate.rateId}>
								<div className={styles.rate_column}>
									<p>
										{index === 0 ? `до ${formatNumberRu(rate.limitTo)}` : `от ${formatNumberRu(rate.limitFrom)}`}
									</p>
								</div>

								<div className={styles.rate_column}>
									<NumberFormat
										customInput={TextField}
										//Свойства TextField Mui5`
										autoComplete={"off"}
										fullWidth
										value={rate.buy ? rate.buy : ""}
										variant="outlined"
										sx={{
											borderRadius: "5px",
											position: "relative",
											"& input": {
												background: "white",
												textAlign: "center",
												fontSize: "20px",
												// fontWeight: "bold",
												padding: "4px 0",
												borderRadius: "5px",
											},
											"& fieldset": {
												textAlign: "center",
											},
											"& label": {
												width: "100%",
												textAlign: "center",
												transformOrigin: "top center",
												left: "-13px",
											},
											"& .Mui-disabled": {
												color: "black !important",
											},
											"&::before": {
												content: `${rate?.buy >= rate?.sale
													? '"Больше чем курс продажи"'
													: index === 1
														? '""'
														: rate?.buy >
															currencyNotByParse?.rates[index + 1].buy
															? '"Больше чем в след. шаге"'
															: '""'
													}`,
												color: "#fd8b8b",
												position: "absolute",
												width: "106%",
												bottom: "-18px",
												fontSize: "15px",
												textAlign: "center",
												fontWeight: "bold",
											},
										}}
										onChange={(e) => {
											setCurrencyNotByParse((state: CurrencyModel) => ({
												...state,
												rates: state.rates.map((rate, i) => {
													if (i === index) {
														return {
															...rate,
															buy: +(+e.target.value.replace(/\s/g, "")).toFixed(2),
														};
													} else {
														return rate;
													}
												}),
											}));
										}}
										//Свойства NumberFormat
										thousandSeparator={" "}
										allowNegative={false}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>

								<div className={styles.rate_column}>
									<NumberFormat
										customInput={TextField}
										//Свойства TextField Mui5
										autoComplete={"off"}
										fullWidth
										value={rate.sale ? rate.sale : ""}
										variant="outlined"
										sx={{
											borderRadius: "5px",
											position: "relative",
											"& input": {
												background: "white",
												textAlign: "center",
												fontSize: "20px",
												// fontWeight: "bold",
												padding: "4px 0",
												borderRadius: "5px",
											},
											"& fieldset": {
												textAlign: "center",
											},
											"& label": {
												width: "100%",
												textAlign: "center",
												transformOrigin: "top center",
												left: "-13px",
											},
											"& .Mui-disabled": {
												color: "black !important",
											},
											"&::before": {
												content: `${rate?.buy >= rate?.sale
													? '"Меньше чем курс покупки"'
													: index === 1
														? '""'
														: rate?.sale <
															currencyNotByParse?.rates[index + 1].sale
															? '"Меньше чем в след. шаге"'
															: '""'
													}`,
												color: "#fd8b8b",
												position: "absolute",
												width: "106%",
												bottom: "-18px",
												fontSize: "15px",
												textAlign: "center",
												fontWeight: "bold",
											},
										}}
										onChange={(e) => {
											setCurrencyNotByParse((state: CurrencyModel) => ({
												...state,
												rates: state.rates.map((rate, i) => {
													if (i === index) {
														return {
															...rate,
															sale: +(+e.target.value.replace(/\s/g, "")).toFixed(2),
														};
													} else {
														return rate;
													}
												}),
											}));
										}}
										//Свойства NumberFormat
										thousandSeparator={" "}
										allowNegative={false}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</React.Fragment>
						))
					}

					{isRatesByParse && (
						<>
							{openWindow === "editCurrency" && <h3>Баланс</h3>}
							<div className={styles.rate_column}>
								<p>Разница с МИГ-ом</p>
							</div>
							<div className={styles.rate_column}>
								<NumberFormat
									customInput={TextField}
									//Свойства TextField Mui5
									autoComplete={"off"}
									fullWidth
									value={currencyByParse.parseBuy ? +currencyByParse.parseBuy : 0}
									variant="outlined"
									sx={{
										borderRadius: "5px",
										position: "relative",
										"& input": {
											background: "white",
											textAlign: "center",
											fontSize: "20px",
											// fontWeight: "bold",
											padding: "4px 0",
											borderRadius: "5px",
										},
										"& fieldset": {
											textAlign: "center",
										},
										"& label": {
											width: "100%",
											textAlign: "center",
											transformOrigin: "top center",
											left: "-13px",
										},
										"& .Mui-disabled": {
											color: "black !important",
										},
									}}
									onChange={(e) => {
										setCurrencyByParse((state: CurrencyModel) => ({
											...state,
											parseBuy: e.target.value.replace(/\s/g, ""),
											rates: state.rates.map((rate, index) => (
												{
													...rate,
													buy: +(+(migRate?.buy ? migRate?.buy : 0) + (index + 1) * +e.target.value.replace(/\s/g, "")).toFixed(2),
												}
											)),
										}));
									}}
									//Свойства NumberFormat
									thousandSeparator={" "}
									allowNegative
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
							<div className={styles.rate_column}>
								<NumberFormat
									customInput={TextField}
									//Свойства TextField Mui5
									autoComplete={"off"}
									fullWidth
									value={currencyByParse.parseSale ? +currencyByParse.parseSale : 0}
									variant="outlined"
									sx={{
										borderRadius: "5px",
										position: "relative",
										"& input": {
											background: "white",
											textAlign: "center",
											fontSize: "20px",
											// fontWeight: "bold",
											padding: "4px 0",
											borderRadius: "5px",
										},
										"& fieldset": {
											textAlign: "center",
										},
										"& label": {
											width: "100%",
											textAlign: "center",
											transformOrigin: "top center",
											left: "-13px",
										},
										"& .Mui-disabled": {
											color: "black !important",
										},
									}}
									onChange={(e) => {
										setCurrencyByParse((state: CurrencyModel) => ({
											...state,
											parseSale: e.target.value.replace(/\s/g, ""),
											rates: state.rates.map((rate, index) => (
												{
													...rate,
													sale: +(+(migRate?.sell ? migRate?.sell : 0) - (index + 1) * +e.target.value.replace(/\s/g, "")).toFixed(2),
												})
											),
										}));
									}}
									//Свойства NumberFormat
									thousandSeparator={" "}
									allowNegative
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>

							{openWindow === "editCurrency" && <div></div>}

							{
								currencyByParse?.rates.map((rate: RateModel, index: number) => (
									<React.Fragment key={rate.rateId}>
										<div className={styles.rate_column}>
											<p>
												{index === 0 ? `до ${formatNumberRu(rate.limitTo)}` : `от ${formatNumberRu(rate.limitFrom)}`}
											</p>
										</div>

										<div className={styles.rate_column}>
											<NumberFormat
												customInput={TextField}
												//Свойства TextField Mui5
												autoComplete={"off"}
												fullWidth
												value={rate.buy ? rate.buy : ""}
												variant="outlined"
												disabled
												sx={{
													borderRadius: "5px",
													position: "relative",
													"& input": {
														background: "white",
														textAlign: "center",
														fontSize: "20px",
														// fontWeight: "bold",
														padding: "4px 0",
														borderRadius: "5px",
													},
													"& fieldset": {
														textAlign: "center",
													},
													"& label": {
														width: "100%",
														textAlign: "center",
														transformOrigin: "top center",
														left: "-13px",
													},
													"& .Mui-disabled": {
														color: "black !important",
													},
													"&::before": {
														content: `${rate?.buy >= rate?.sale
															? '"Больше чем курс продажи"'
															: index === 1
																? '""'
																: rate?.buy >
																	currencyByParse?.rates[index + 1].buy
																	? '"Больше чем в след. шаге"'
																	: '""'
															}`,
														color: "#fd8b8b",
														position: "absolute",
														width: "106%",
														bottom: "-18px",
														fontSize: "15px",
														textAlign: "center",
														fontWeight: "bold",
													},
												}}
												//Свойства NumberFormat
												thousandSeparator={" "}
												allowNegative={false}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>

										<div className={styles.rate_column}>
											<NumberFormat
												customInput={TextField}
												//Свойства TextField Mui5
												autoComplete={"off"}
												fullWidth
												value={rate.sale ? rate.sale : ""}
												variant="outlined"
												disabled
												sx={{
													borderRadius: "5px",
													position: "relative",
													"& input": {
														background: "white",
														textAlign: "center",
														fontSize: "20px",
														// fontWeight: "bold",
														padding: "4px 0",
														borderRadius: "5px",
													},
													"& fieldset": {
														textAlign: "center",
													},
													"& label": {
														width: "100%",
														textAlign: "center",
														transformOrigin: "top center",
														left: "-13px",
													},
													"& .Mui-disabled": {
														color: "black !important",
													},
													"&::before": {
														content: `${rate?.buy >= rate?.sale
															? '"Меньше чем курс покупки"'
															: index === 1
																? '""'
																: rate?.sale <
																	currencyByParse?.rates[index + 1].sale
																	? '"Меньше чем в след. шаге"'
																	: '""'
															}`,
														color: "#fd8b8b",
														position: "absolute",
														width: "106%",
														bottom: "-18px",
														fontSize: "15px",
														textAlign: "center",
														fontWeight: "bold",
													},
												}}
												//Свойства NumberFormat
												thousandSeparator={" "}
												allowNegative={false}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>

										{openWindow === "editCurrency" && index === 0 &&
											<div className={styles.rate_column} style={{ gridRow: "span 2" }}>
												<NumberFormat
													customInput={TextField}
													//Свойства TextField Mui5
													autoComplete={"off"}
													fullWidth
													value={newCurrency.cash?.balance || 0}
													variant="outlined"
													disabled
													sx={{
														borderRadius: "5px",
														position: "relative",
														height: "100%",
														"& input": {
															background: "white",
															textAlign: "center",
															fontSize: "20px",
															// fontWeight: "bold",
															padding: "0",
															borderRadius: "5px",
															height: "100%"
														},
														"& .MuiInputBase-root": {
															height: "100%",
														},
														"& .MuiFormControl-root": {
															height: "100%",
														},
														"& fieldset": {
															textAlign: "center",
														},
														"& label": {
															width: "100%",
															textAlign: "center",
															transformOrigin: "top center",
															left: "-13px",
														},
														"& .Mui-disabled": {
															color: "black !important",
														},
													}}
													//Свойства NumberFormat
													thousandSeparator={" "}
													allowNegative={false}
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</div>
										}
									</React.Fragment>
								))
							}
						</>
					)}
				</div>

				<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", }}>
					<Button
						variant="contained"
						size="large"
						startIcon={<ClearIcon />}
						sx={{
							background: "#b70000",
							"&:hover": {
								background: "#7f0101",
							},
						}}
						onClick={handleCloseWindow}
					>
						Отменить
					</Button>

					{openWindow === "addCurrency" && (
						<Button
							variant="contained"
							size="large"
							startIcon={<DoneIcon />}
							sx={{
								background: "#037703",
								"&:hover": {
									background: "#005f00",
								},
							}}
							disabled={validateAddCurrency(
								isRatesByParse
									? currencyByParse
									: currencyNotByParse
							)}
							onClick={handleAddCurrency}
						>
							Добавить валюту
						</Button>
					)}
					{openWindow === "editCurrency" && (
						<Button
							variant="contained"
							size="large"
							startIcon={<DoneIcon />}
							sx={{
								background: "#037703",
								"&:hover": {
									background: "#005f00",
								},
							}}
							disabled={validateAddCurrency(
								isRatesByParse
									? currencyByParse
									: currencyNotByParse
							)}
							onClick={async () => {
								const currentCurrency = isRatesByParse
									? currencyByParse
									: currencyNotByParse;

								await addEditCurrencies({ ...currentCurrency, fromParse: isRatesByParse });
							}}
						>
							Добавить изменение
						</Button>
					)}
				</div>
			</div>
		</ModalWindow>
	);
};