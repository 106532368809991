import { DepartmentModel, CurrencyModel, Regions } from '@/interface';
import { api } from '@/api/Api';
import pm from "@/core/helpers/ProcessManager";

export const SET_DEPARTMENT = '@@departmentReducer/SET_DEPARTMENT';
export const SET_LOADING = '@@departmentReducer/SET_LOADING';
export const SET_ERROR = '@@departmentReducer/SET_ERROR';
export const SET_ACTIVE_DEPARTMENT_ID = '@@departmentReducer/SET_ACTIVE_DEPARTMENT_ID';
export const SET_ACTIVE_DEPARTMENT_ADDRESS = '@@departmentReducer/SET_ACTIVE_DEPARTMENT_ADDRESS';
export const SET_ACTIVE_CURRENCY_ID = '@@departmentReducer/SET_ACTIVE_CURRENCY_ID';
export const SET_ACTIVE_REGION = '@@departmentReducer/SET_ACTIVE_REGION';
export const SET_NEW_CURRENCY = '@@departmentReducer/SET_NEW_CURRENCY';
export const RESET_NEW_CURRENCY = '@@departmentReducer/RESET_NEW_CURRENCY';
export const SET_ACTIVE_KASSA_ID = '@@departmentReducer/SET_ACTIVE_KASSA_ID';
export const SET_KASSA_IS_OPEN = '@@departmentReducer/SET_KASSA_IS_OPEN';

export const setDepartments = (departmentList: DepartmentModel[]) => ({
	type: SET_DEPARTMENT,
	payload: {
		departmentList
	},
});

export const setLoading = () => ({
	type: SET_LOADING
});

export const setError = () => ({
	type: SET_ERROR
});

export const setActiveDepartmentId = (departmentId: number | string) => ({
	type: SET_ACTIVE_DEPARTMENT_ID,
	payload: {
		departmentId
	},
});

export const setActiveDepartmentAddressLanding = (departmentAddress: string) => ({
	type: SET_ACTIVE_DEPARTMENT_ADDRESS,
	payload: {
		departmentAddress
	}
});

export const setActiveCurrencyId = (departmentId: number | string, currencyId: number | string) => ({
	type: SET_ACTIVE_CURRENCY_ID,
	payload: {
		currencyId,
		departmentId
	},
});

export const setActiveRegion = (region: Regions | 'Все') => ({
	type: SET_ACTIVE_REGION,
	payload: { region }
});

export const setNewCurrency = (currency: CurrencyModel) => ({
	type: SET_NEW_CURRENCY,
	payload: { newCurrency: currency }
});

export const resetNewCurrency = () => ({
	type: RESET_NEW_CURRENCY
});

export const setActiveKassaId = (kassaId: number | string) => ({
	type: SET_ACTIVE_KASSA_ID,
	payload: { kassaId }
});

export const setKassaIsOpen = (isOpen: boolean) => ({
	type: SET_KASSA_IS_OPEN,
	payload: { kassaIsOpen: isOpen },
});

export const loadDepartments: any = () => (dispatch?: any) => {
	pm.openBackDrop();
	dispatch(setLoading());

	api.department.getDepartments()
		.then((res) => {
			const departmentList = res?.value.filter((department: DepartmentModel) => department.isActive === 1);
			for (const department of departmentList) {

				department.currencies.sort(
					(a: CurrencyModel, b: CurrencyModel) => {
						if (a.code === 'CNY' && b.code !== 'USD' && b.code !== 'EUR' && b.code !== 'RUB') {
							return -1;
						} else if (a.code === 'EUR' && b.code !== 'USD' && b.code !== 'RUB') {
							return -1;
						} else if (a.code === 'RUB' && b.code !== 'USD') {
							return -1;
						} else if (a.code === 'USD') {
							return -1;
						} else if (a.code === "GOLD100" && b.code !== "GOLD50" && b.code !== "GOLD20" && b.code !== "GOLD10" && b.code !== "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD50" && b.code !== "GOLD20" && b.code !== "GOLD10" && b.code !== "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD20" && b.code !== "GOLD10" && b.code !== "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD10" && b.code !== "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD1") {
							return -1;
						} else {
							return 0;
						}
					}
				);

				console.log(department)
			}

			dispatch(setDepartments(departmentList));
		})
		.catch(() => {
			dispatch(setError());
			pm.openSnackBar("Произошла ошибка: Не удалось получить данные по отеделениям", { severity: "error" });
		})
		.finally(() => pm.closeBackDrop());
};

export const loadDepartmentsLanding: any = () => (dispatch?: any) => {
	localStorage.removeItem("state");
	pm.openBackDrop();
	dispatch(setLoading());

	api.department.getDepartmentsLanding()
		.then((res) => {
			const departmentList = res?.filter((department: DepartmentModel) => department.isActive === 1);
			for (const department of departmentList) {

				department.currencies.sort(
					(a: CurrencyModel, b: CurrencyModel) => {
						if (a.code === 'CNY' && b.code !== 'USD' && b.code !== 'EUR' && b.code !== 'RUB') {
							return -1;
						} else if (a.code === 'EUR' && b.code !== 'USD' && b.code !== 'RUB') {
							return -1;
						} else if (a.code === 'RUB' && b.code !== 'USD') {
							return -1;
						} else if (a.code === 'USD') {
							return -1;
						} else if (a.code === "GOLD100" && b.code !== "GOLD50" && b.code !== "GOLD20" && b.code !== "GOLD10" && b.code !== "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD50" && b.code !== "GOLD20" && b.code !== "GOLD10" && b.code !== "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD20" && b.code !== "GOLD10" && b.code !== "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD10" && b.code !== "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD5" && b.code !== "GOLD1") {
							return -1;
						} else if (a.code === "GOLD1") {
							return -1;
						} else {
							return 0;
						}
					}
				);
			}

			dispatch(setDepartments(departmentList));
		})
		.catch(() => {
			dispatch(setError());
			pm.openSnackBar("Произошла ошибка: Не удалось получить данные по отеделениям", { severity: "error" });
		})
		.finally(() => pm.closeBackDrop());
};