import axios from "axios";
import AuthUtil from '@/core/helpers/AuthUtil';

const wenv = process.env;

export const searchRequest = (body: {
  businessKey: string | null,
  status: string | null,
  iin: string | null, //
  amount: string | null, //
  currencyCode: string | null, //
  actionType: string | null,
  lastname: string | null,
  firstname: string | null,
  middlename: string | null,
  phone: string | null, //
  startDate: Date | null,
  endDate: Date | null,
  depId: string | number | null
}) => {
  const auth = AuthUtil.getAuth();
  return axios
    .post(`${wenv.REACT_APP_API_URL}/Application/search`,
      {
        ...body
      },
      {
        headers: {
          accept: "*/*",
          "Authorization": `Bearer ${auth.token?.accessToken}`
        },
      })
    .then((res: any) => res.data)
    .catch((e) => {
      console.error(e);
    });
};

export const getReportList = (page: number) =>
  axios
    .get(
      `${wenv.REACT_APP_API_URL}/CashierReport/report?page=${page}`,
    )
    .then((res: any) => res.data)
    .catch(e => console.error(e));

export const getReport = (id: string) =>
  axios
    .get(
      `${wenv.REACT_APP_API_URL}/CashierReport/report?reportId=${id}`,
    )
    .then((res: any) => res.data)
    .catch(e => console.error(e));