import { api } from '@/api/Api';
import { CurrencyModel, MigRate, NationalBankRate } from '@/interface';
import { useEffect, useState } from 'react';
import pm from "@/core/helpers/ProcessManager";

export const useModalCurrencyActions = (
	newCurrency: CurrencyModel,
	editMode: boolean,
	handleGetFillialList: () => void,
	handleCloseWindow: () => void,
	migRate?: MigRate,
) => {
	const [isRatesByParse, setIsRatesByParse] = useState(false);
	const [currencyByParse, setCurrencyByParse] = useState<CurrencyModel>({
		code: "",
		description: "",
		country: "",
		spread: 0,
		margin: 0,
		isActive: true,
		depId: 0,
		fromParse: false,
		parseBuy: "",
		parseSale: "",
		parseSite: "",
		step: "",
		rates: [
			{
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
			{
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		],
	});
	const [currencyNotByParse, setCurrencyNotByParse] = useState<CurrencyModel>({
		code: "",
		description: "",
		country: "",
		spread: 0,
		margin: 0,
		isActive: true,
		depId: 0,
		fromParse: false,
		parseBuy: "",
		parseSale: "",
		parseSite: "",
		step: "",
		rates: [
			{
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
			{
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		],
	});

	useEffect(() => {
		loadMigRates();

		setIsRatesByParse(newCurrency.fromParse);
	}, []);

	const loadMigRates = async () => {
		const valueOfStep = await updateStep(newCurrency.code);

		if (migRate) {
			if (!newCurrency.rates[0].buy) {
				setCurrencyNotByParse((state: CurrencyModel) => ({
					...newCurrency,
					fromParse: false,
					parseBuy: "",
					parseSale: "",
					parseSite: "",
					step: "",
					rates: [
						{
							...state.rates[0],
							limitFrom: 0,
							limitTo: newCurrency.code.toUpperCase() === "USD" ? 10000 : valueOfStep,
							buy: +migRate.buy,
							sale: +migRate.sell,
						},
						{
							...state.rates[1],
							limitFrom: newCurrency.code.toUpperCase() === "USD" ? 10000 : valueOfStep,
							limitTo: 0,
							buy: +migRate.buy,
							sale: +migRate.sell,
						},
					],
				}));

				setCurrencyByParse((state: CurrencyModel) => ({
					...state,
					fromParse: true,
					parseSite: "mig.kz",
					rates: [
						{
							...state.rates[0],
							limitFrom: 0,
							limitTo: newCurrency.code.toUpperCase() === "USD" ? 10000 : valueOfStep,
							buy: +migRate.buy,
							sale: +migRate.sell,
						},
						{
							...state.rates[1],
							limitFrom: newCurrency.code.toUpperCase() === "USD" ? 10000 : valueOfStep,
							limitTo: 0,
							buy: +migRate.buy,
							sale: +migRate.sell,
						},
					]
				}));
			} else {
				setCurrencyNotByParse(() => ({
					...newCurrency,
					rates: [
						{
							...newCurrency.rates[0],
							limitFrom: 0,
							limitTo: newCurrency.code.toUpperCase() === "USD" ? 10000 : valueOfStep,
						},
						{
							...newCurrency.rates[1],
							limitFrom: newCurrency.code.toUpperCase() === "USD" ? 10000 : valueOfStep,
							limitTo: 0,
						},
					],
				}));

				setCurrencyByParse(() => ({
					...newCurrency,
					rates: [
						{
							...newCurrency.rates[0],
							limitFrom: 0,
							limitTo: newCurrency.code.toUpperCase() === "USD" ? 10000 : valueOfStep,
						},
						{
							...newCurrency.rates[1],
							limitFrom: newCurrency.code.toUpperCase() === "USD" ? 10000 : valueOfStep,
							limitTo: 0,
						},
					]
				}));
			}
		}
	};

	const validateAddCurrency = (currency: CurrencyModel) => {
		let boolean = false;
		for (let i = 0; i < currency?.rates.length; i++) {
			if (
				i === 0 &&
				(+currency.rates[i].buy > +currency.rates[i + 1].buy ||
					+currency.rates[i].sale < +currency.rates[i + 1].sale ||
					+currency.rates[i].buy >= +currency.rates[i].sale)
			) {
				boolean = true;
				break;
			}

			if (i === 1 && +currency.rates[i].buy > +currency.rates[i].sale) {
				boolean = true;
				break;
			}
		}

		if (currency?.code === "USD") {
			for (let i = 0; i < currency?.rates.length; i++) {
				if (+currency.rates[i].buy - +currency.rates[i].sale < -15) {
					boolean = true;
					pm.openSnackBar("Разница между покупкой и продажей превышает 15 тг", { severity: "error" });
					break;
				}
			}
		}

		if (currency?.code === "EUR") {
			for (let i = 0; i < currency.rates.length; i++) {
				if (+currency.rates[i].buy - +currency.rates[i].sale < -20) {
					boolean = true;
					pm.openSnackBar("Разница между покупкой и продажей превышает 20 тг", { severity: "error" });
					break;
				}
			}
		}

		if (editMode) {
			if (newCurrency.fromParse !== isRatesByParse) {
				boolean = false;
			} else if (newCurrency.rates[0].buy === currency.rates[0].buy
				&& newCurrency.rates[0].sale === currency.rates[0].sale
				&& newCurrency.rates[1].buy === currency.rates[1].buy
				&& newCurrency.rates[1].sale === currency.rates[1].sale
				&& newCurrency.fromParse === currency.fromParse
			) {
				boolean = true;
			}
		}

		return boolean;
	};

	const handleAddCurrency = async () => {
		pm.openBackDrop();

		const currency: any = isRatesByParse ? currencyByParse : currencyNotByParse;
		const rates = [...currency.rates];
		const copyCurrency = { ...currency };
		delete copyCurrency.rates;
		delete copyCurrency.cash;
		delete copyCurrency.step;

		await api.currency.addCurrency({ ...copyCurrency, filId: newCurrency.depId })
			.then(async (res) => {
				if (res) {
					await rates.forEach(async (rate) => {
						await api.rate.addRate({ ...rate, currencyId: res.value.currencyId });
					});

					await handleGetFillialList();
					handleCloseWindow();
				}
			})
			.catch(() => pm.openSnackBar('Произошла ошибка: не удалось добавить баланс'))
			.finally(() => pm.closeBackDrop());
	};

	const updateStep = async (currencyCode: string) => {
		const nationalBankRates = await api.parsing.loadNationalRates()
			.then((res) => res.rates.item);

		const currentNationalBankCurrency = nationalBankRates?.find(
			(item: NationalBankRate) => item.title === currencyCode.toUpperCase()
		);
		const usdNationalBankCurrency = nationalBankRates?.find(
			(item: NationalBankRate) => item.title === "USD"
		);

		let valueOfStep = 0;
		if (usdNationalBankCurrency && currentNationalBankCurrency) {
			valueOfStep =
				(10000 * +usdNationalBankCurrency?.description) /
				+currentNationalBankCurrency?.description;
		} else {
			valueOfStep = 0;
		}

		if (valueOfStep > 10 && valueOfStep < 100) {
			valueOfStep = valueOfStep - (valueOfStep % 10);
		} else if (valueOfStep > 100 && valueOfStep < 1000) {
			valueOfStep = valueOfStep - (valueOfStep % 100);
		} else if (valueOfStep > 1000 && valueOfStep < 10000) {
			valueOfStep = valueOfStep - (valueOfStep % 1000);
		} else if (valueOfStep > 10000 && valueOfStep < 100000) {
			valueOfStep = valueOfStep - (valueOfStep % 10000);
		} else if (valueOfStep > 100000) {
			valueOfStep = valueOfStep - (valueOfStep % 100000);
		}

		return valueOfStep;
	};

	return {
		isRatesByParse,
		setIsRatesByParse,
		currencyNotByParse,
		setCurrencyNotByParse,
		currencyByParse,
		setCurrencyByParse,
		validateAddCurrency,
		handleAddCurrency,
	};
};